export default {
    userInfo(state){
        return {...state.user_info}
    },
    contacts(state){
      const {contacts, ...otherProps} = state.contacts
      return {
        ...otherProps,
        contacts: contacts.map(a => ({...a}))
      }
    },
    mails(state){
        return [...state.mails]
    },
    folders(state){
        return [...state.folders]
    },
    getFoldersCollectors(state){
        return [...state.foldersCollectors]
    },
    labels(state){
        return [...state.labels]
    },
    user(state){
        return {...state.user}
    },
    getTotal(state){
        return state.total
    },
    getAll(state){
        return state.all
    },
    opened_mail(state){
        return {...state.opened_mail}
    },
    getAccount(state){
        return {...state.account}
    },
    getLeftSidebarSelect(state){
        return state.left_sidebar_select
    },
    getUserSettings(state){
        return {...state.settings}
    },
    getController(state){
        return state.controller
    },
    getLanguage(state){
        return state.language
    },
    getFilters(state){
        return [...state.filters]
    },
    getConnection(state){
        return state.connection
    },
    getGroups(state){
      return [...state.groups]
    },
    getSearch(state){
      return state.isSearch
    },
    getSearchPagination(state){
      return {...state.searchPagination}
    },
    getBa(state){
        return state.ba?.slice()
    },
    getCollectors(state){
        return state.collectors
    }
}
