<template>
<div class="main__container" v-if="!isNotLoading">
    <base-dialog :show="!!error" @close="handleError"
    :widthProgressBar='widthProgressBar' title="An error occured!">
        <p>{{error}}</p></base-dialog>
    <base-dialog :show="!!success" 
      @close="handleError" 
      :isError="false"
      :widthProgressBar='widthProgressBar' 
      title="Success!">
        <p>{{success}}</p></base-dialog>
    <left-sidebar v-if="account.aliases"
    @create_new_mail="create_mail(true,0)"
    @set_active="setActive"
    :email="email"
    :isMobile="isMobile"
    :lang="lang"
    :activeEmail='activeEmail'
    :counter_click_folder="counter_click_folder"
    :folders="folders"
    ref="leftSidebar"
    :active="active"
    :account="account"
    :amount="amount"
    :subfolders="subfolders"
    :mailIsOpen="mailIsOpen"
    @search_mail="search_mail"
    @create_folder="createFolder"
    @get_label_mails="getLabelMails"
    @refresh="refresh"
    @move_to="move_to"
    @open_folder="open_folder"
    @labels_loaded="labels_loaded"
    @add_label="add_label"></left-sidebar>
  <section class="box" :class="{'box--split': mailIsOpen && !isMobile && boxSplit, 'box--empty': isEmptyMailsList}"
    id="box_id">
    <!-- account?.aliases?.length > 0 -->
    <header-component v-if="account?.email"
      :isMobile="isMobile"
      @previous='previous'
      @next='next'
      :amount="amount"
      :pages_amount='pages_amount'
      :total="total"
      :page='page'
      :lang="lang"
      :hideMailsList="hideMailsList"
      :mailIsOpen="mailIsOpen"
      :email="email"
      :hasChecked="hasChecked"
      :isCheckedALL="isCheckedALL"
      :subfolders="subfolders"
      :isLoading="isLoadingMails"
      :account="account"
      :indexMail="indexMail"
      :labels="labels"
      :isCheckedAllFolder="isCheckedAllFolder"
      :activeEmail='activeEmail'
      :counter_click_folder="counter_click_folder"
      @add_label="add_label"
      @remove_all="remove_all"
      @previous_letter="previous_letter"
      @next_letter="next_letter"
      @move_to="move_to"
      @refresh="refresh"
      @mark_as_read="mark_as_read"
      @select_mails="selectMails"
      @search_mail="search_mail"
      @return_to_mailslist="returnToMailslist"
      @delete_mail='delete_mail2(null)'>
    </header-component>
    <router-view
      v-if="account?.settings?.per_page && folders?.length > 0"
      :isEmptyMailsList="isEmptyMailsList"
      :slim="slim"
      :hideMailsList="hideMailsList"
      :mailIsOpen="mailIsOpen"
      :isMobile="isMobile"
      :showNotification="showNotification"
      :isCheckedAllFolder="isCheckedAllFolder"
      :sticky="sticky"
      :checked_mails="checked_mails"
      :all="all"
      :folderName="folderName()"
      :mailsFinal="mailsFinal"
      :hasChecked="hasChecked"
      :activeMail="activeMail"
      :getJunkId="getJunkId"
      :lang="lang"
      :subfolders="subfolders"
      @select_mails="selectMails"
      @full_screen_letter2="fullScreenLetter2"
      @start_drag="startDrag"
      @create_new_mail2="create_new_mail2"
      @delete_msg="delete_mail3"
      @mark_favorite="mark"
      @move_to="move_to"
      @select_mail="select_mail"
      @open-mail="openMail"

      :isCreated="isCreated"
      :mails="mails"
      :activeEmail="activeEmail"
      :image_display="image_display"
      :split_call="split_call"
      :subject="subject"
      :mails_item="mailsItem"
      :labels="labels"
      @get_label_mails="getLabelMails"

      v-model="isLoadingMails"
      v-model:checked_mails="checked_mails"
      @load_mails="loadMails"

      @split="split"
      @close-mail="closeMail"
      @create_new_mail="create_mail"
      @full_screen_mail="full_screen_mail"
      @add_label="add_label"
      @create_new_mail_with_email="create_new_mail_with_email"
      @mail_is_loaded="mail_is_loaded"
    />
    <folder-creation 
      v-if="isOpenedFolderCreation" 
      :subfolders="subfolders.filter(el => !el.isCollector)"
      @save="sendFolderCreation"
      @close="closeFolderCreation">
    </folder-creation>
    <labels-creation
      v-if="isOpenedLabelsCreation"
      :activeEmail='activeEmail'
      :subfolders="subfolders.filter(el => !el.isCollector)"
      @save="sendLabelsCreation"
      @close="closeLabelsCreation">
    </labels-creation>
  </section>
  <create-mail
    v-if="isNewMailOpen"
    @close_create_mail="close_create_mail"
    :is_sending_mail="isSendingMail"
    :mail='dataForCreateMail'
    :to_all="toAll"
    :email="email"
    :lang="lang"
    :email_to="email_to"
    :account="account"
    :subfolders="subfolders"
    ref="createMailComponent"
  ></create-mail>
    <div class="popup" v-if="areYouSure">
      <div class="popup__container">
        <form class="popup__form">
          <h2 class="popup__title">{{ $t('mails_removing') }}
          </h2>
          <p class="popup__text">{{ $t('are_you_sure7') }} {{ isSearch ? $t('in_search') : activeEmail > 0 ? $t('folder') : $t('label') }} "{{ isSearch ? (search_values.request.from || search_values.request.subject || search_values.request.text) : folder_name}}"?</p>
          <button class="button button--danger button--small remove_all" @click="remove_all()" type="button">
            <span class="button__text button__text--no-icon">
              {{!isRemoveAll ? $t('yes') : ''}}
              <second-spinner v-if="isRemoveAll" class="spinner_class"></second-spinner>
            </span>
          </button>
          <button class="button button--secondary button--small"
                  @click="areYouSure = false; "
                  :disabled="isRemoveAll"
                  type="button">
            <span class="button__text button__text--no-icon">{{ $t('cancel') }}</span>
          </button>
        </form>
      </div>
    </div>
    <confirm-dialog v-if="areYouSure2"
      :topic="$t('mails_removing')"
      :text="$t('are_you_sure8')"
      @confirm_dialog="areYouSure2 = false; delete_mail(uid_for_removing)"
      @cancel_dialog="areYouSure2 = false; uid_for_removing = null"
    ></confirm-dialog>
  <div class="loader" v-if="processing">
    <div class="loader__spinner"></div>
  </div>
</div>
<div class="main__wrapper" v-if="!isNewMailOpen && !mailIsOpen">
  <button class="button button--message" type="button" @click="create_mail(true,0)">
    <span class="button__text">
      <SvgSprite class="icon" symbol="edit" size="32"/>
      {{$t("new_msg")}}
    </span>
  </button>
</div>
</template>
<script>
import LeftSidebar from "@/components/left-sidebar/LeftSidebar.vue";
import CreateMail from "./new_mail/CreateMail.vue";
import HeaderComponent from "../header/HeaderComponent.vue";
import FolderCreation from './FolderCreation.vue';
import LabelsCreation from './LabelsCreation.vue';
import {resizer} from '../helpers/_resizer.js'
import {split_js, destroy_split} from '../helpers/split-view.js'
import PushMixin from '../mixins/PushMixin';
import ConfirmDialog from '../ui/ConfirmDialog.vue'
export default {
  name: "MailPage",
  components: {
    LeftSidebar,
    CreateMail,
    HeaderComponent,
    FolderCreation,
    LabelsCreation,
    ConfirmDialog,
  },
  inheritAttrs: false,
  emits: ['onload_page', 'change_status'],
  mixins: [PushMixin],
  data() {
    return {
      mails: [],
      mailIsOpen: false,
      activeMail: -1,
      isNewMailOpen: false,
      flags: ['trash', "sent", "junk", "draft"],
      success: null,
      isLoading: true,
      opened_mail: {},
      dataForCreateMail: {},
      amount: 30,
      page: 1,
      pages_amount: 1,
      total: 0,
      all: 0,
      hideMailsList: false,
      email: '',
      folders: [],
      active: -1,
      activeEmail: -1,
      toAll: 0,
      lang: 'ru',
      checked_mails: [],
      subject: '',
      mailsItem: {},
      subfolders: [],
      isEmptyMailsList: false,
      isLoadingMails: true,
      isNotLoading: true,
      isCreated: false,
      mailIsNotLoading: false,
      firstCheckboxWithShift: -1,
      firstUncheckedCheckboxWithShift: -1,
      email_to: '',
      account: {},
      indexMail: -1,
      labels: [],
      widthProgressBar: 0,
      settings: {},
      isOpenedFolderCreation: false,
      isOpenedLabelsCreation: false,
      isCheckedALL: false,
      isCheckedAllFolder: false,
      areYouSure: false,
      areYouSure2: false,
      folder_name: '',
      controller: null,
      controller2: null,
      controller3: null,
      controllerFolders: null,
      delay: 700,
      clicks: 0,
      first_click_uid: 0,
      timer: null,
      wmid: '',
      counter_click_folder: 1,
      isRemoveAll: false,
      split_call: true,
      isMobile: false,
      boxSplit: false,
      isSearch: false,
      search_values: {},
      isSearchPageChanged: false,
      uid_for_removing: null,
      isSendingMail: false,
      image_display: 'always',
      show_images: false,
      refresh_search_counter: 1,
      slim: false,
      sticky: false,
      showNotification: false,
      processing: false,
      mailIsLoadedInFirstLoad: true,
      isLoadingFolders: true
    };
  },
  computed: {
    getJunkId(){
      const i = this.subfolders.findIndex(el => el.name === 'INBOX.Junk')
      if(i !== -1){
        return this.subfolders[i].id
      }
      return -1
    },
    hasChecked(){
      if(this.checked_mails.length > 0 || this.activeMail !== -1){
        return true
      }
      return false
    },
    computedEmail(){
      return this.$store.getters['user'].Email
    },
    mailsFinal(){
      if(this.mails && this.mails.length > 0){
        return this.mails.filter( mail => !mail['isChainLetter'])
      }
      return []
    },
    getConnection(){
      return this.$store.getters["getConnection"]
    },
  },
  watch: {
    getConnection(next){
      this.setConnectionOnMessage(next)
    },
    computedEmail(newValue){
        this.isNotLoading = false
        this.email = newValue
        this.setConnectionOnMessage()
        this.loadUserAccount()
    },
    '$route.params.msg_id'(val){
      if(val) {
        if(!this.isLoadingFolders && !this.isCreated) {
          this.openMail(+val)
        }
      } else {
        this.closeMail()
      }
    },
    '$route.params.f_id'(val){
      if(val)
        this.setActive('folder', +val)
    },
    async '$route.params.page'(val, prev){  
      if(val){
        this.page = +val
        const route_name = this.$route.name
        if(route_name !== 'search' && route_name !== 'search_message'){
          const f_id = +this.$route.params.f_id
          const l_id = +this.$route.params.l_id
          if(f_id && f_id === this.activeEmail && prev) {
            await this.loadMails(this.activeEmail, true)
          }
          else if(l_id)
            await this.getLabelMails(l_id)
        }
      }
    },
    async '$route.params.l_id'(val, prev){
      const route_name = this.$route.name
      if(val && !prev){
        const page = +this.$route.params.page
        this.page = page
      }
      if(val && route_name !== 'search' && route_name !== 'search_message'){
        this.setActive('labels', -1)
        await this.getLabelMails(+val)
      }
    },
    async '$route'(val, prev){
      if(val.params.a_id !== prev.params.a_id && (prev.name === 'message' || prev.name === 'search_message' || prev.name === 'label_message')
        && (val.name === 'message' || val.name === 'search_message' || val.name === 'label_message')){
        let name = 'folder'
        if(val.name === 'search_message')
          name = 'search'
        else if(val.name === 'label_message')
          name = 'label'
        await this.$router.push({name: name, params: {...val.params}})
      }
      if(!this.isLoadingFolders && val.name === 'mail'){
        this.active = this.folders[0].id
        this.activeEmail = this.active;
        this.page = 1
        this.$router.push({name: 'folder', params: {a_id: 'all', f_id: this.active, page: this.page}})
      }

      const msg_id = val.params.msg_id
      if(!msg_id && this.mailIsOpen)
        this.closeMail()
    }
  },
  methods: {
    mail_is_loaded(){
        this.opened_mail = this.$store.getters["opened_mail"]
    },
    split(){
      split_js()
      this.split_call = false
    },
    async create_new_mail2(isEmpty, toAll, uid){
      if(this.activeEmail > 0){
        const j = this.subfolders.findIndex(el => el.id === this.activeEmail)
        if(j!==-1 && this.subfolders[j].name){
          const arr = this.subfolders[j].name.split(".")
          if(arr.length > 1 && arr[1] === "Drafts"){
            await this.loadMail2(uid, true);
          } else{
            await this.loadMail2(uid, false);
          }
        }
      } else {
        await this.loadMail2(uid, false);
      }
      this.create_mail(isEmpty, toAll)
    },
    async loadMail2(id, isDraft=false, isShowingImages=false){
      this.opened_mail = {}
      this.isLoading = true;
      if(this.controller2){
        this.controller2.abort()
        this.controller2 = null
      }
      this.controller2 = new AbortController()
      let actionPayload = {
        controller: this.controller2
      };
      const i = this.mails.findIndex(el => el.uid === id)

      const isSearch = await this.$store.getters['getSearch']
      if(this.activeEmail > 0 && !isSearch){
        actionPayload['path'] = this.activeEmail+'/'+id
      } else {
        if(i!==-1){
          actionPayload['path'] = this.mails[i].folder_id +'/'+id
        }
      }
      if(!!actionPayload['path'] && this.image_display === 'always' && !isShowingImages){
        actionPayload['path'] += "?show_images=true"
        this.show_images=true
      }else if(!!actionPayload['path'] && this.image_display === 'never' && !isShowingImages){
        this.show_images=false
      }
      try {
        await this.$store.dispatch("getMail", actionPayload);
        this.controller2 = null
        this.opened_mail = this.$store.getters["opened_mail"]
        if(isDraft){
          this.dataForCreateMail = {...this.opened_mail.message}
          this.dataForCreateMail['isDraft']=true
          if(this.isNewMailOpen){
            this.isNewMailOpen = false
          }
          setTimeout(()=>{
            this.isNewMailOpen = true;
          },200)
        }
        this.mailIsNotLoading = false
      }catch (err) {
        this.errorHandler(err)
        this.closeMail()
        this.activeMail = -1
      }
      this.isLoading = false;
    },
    setConnectionOnMessage(next){
      const context = this
        const connection = next
        if(connection){
          connection.onmessage = async function (event) {
            if(event.data){
              let data = JSON.parse(event.data)
              if(data.Body && data.Body.action){
                if(data.Body.mailbox && (data.Body.action === 'new' || data.Body.action === 'store' || data.Body.action === 'move') && context.subfolders){
                  const i = context.subfolders.findIndex(el => el.name === data.Body.mailbox)
                  if(i!==-1){
                    if(context.active === context.subfolders[i].id)
                      context.refresh(true)
                  }
                }
                if(data.Body.mailbox && data.Body.action === 'updateMailbox' && context.subfolders && data.Body.unseen){
                  await context.loadFolders(true)
                  const i = context.subfolders.findIndex(el => el.name === data.Body.mailbox)
                  if(i!==-1 && context.$refs.leftSidebar)
                    context.$refs.leftSidebar.updateFolderUnseenInMobile(context.subfolders[i], context.subfolders[i].unread)
                }
                if(data.Body.action === 'updateMailboxList'){
                  context.loadFolders(true)
                }
              }
            }
          }
        }
    },
    add_label(){
      this.isOpenedLabelsCreation = true
    },
    async sendLabelsCreation(actionPayload){
      this.isLoading = true;
      this.isOpenedLabelsCreation = false
      try {
        await this.$store.dispatch('createLabel', actionPayload)
        this.$refs.leftSidebar.loadLabels()
        this.labels = this.$store.getters['labels']
      } catch (err) {
        this.errorHandler(err)
      } 
      this.isLoading = false;
    },
    closeLabelsCreation(){
      this.isOpenedLabelsCreation = false
    },
    closeFolderCreation(){
      this.isOpenedFolderCreation = false
    },
    createFolder(){
      this.isOpenedFolderCreation = true
    },
    async sendFolderCreation(id, name){
      this.error = null;
      this.isLoading = true;
      let actionPayload = {
        folder: {
          folder: {
            parent_id: id,
            name: name
          }
        }
      }
      this.isOpenedFolderCreation = false
      try {
        await this.$store.dispatch("createFolder", actionPayload);
        await this.$store.dispatch("getFolders");
        this.folders = this.$store.getters["folders"];
        this.subfolders = this.folders.slice()
        this.getAllFolders(this.folders[0])
      } catch (err) {
        this.errorHandler(err)
      }
      this.isLoading = false;
    },
    labels_loaded(labels){
      this.labels = labels
    },
    async next_letter(){
      const letters = await this.$store.getters["mails"];
      if(this.indexMail-(this.page-1)*this.amount < letters.length && this.indexMail !== -1){
        if(this.indexMail-(this.page-1)*this.amount-1 >= 0){
          if(letters[this.indexMail-(this.page-1)*this.amount]){
            this.indexMail++
            this.openMail(letters[this.indexMail-(this.page-1)*this.amount-1].uid)
          }
        }
      } else if(this.indexMail-(this.page-1)*this.amount === letters.length){
        if(this.page*this.amount < this.total){
          if(this.page < this.pages_amount){
            this.page++
            if(this.isSearch){
              this.search_values['page'] = this.page
              this.isSearchPageChanged = true
              await this.search_mail(this.search_values)
              this.isSearchPageChanged = false
            } else await this.changePage()
            setTimeout(()=>{
              this.indexMail = (this.page-1)*this.amount+1
              if(this.mails.length > 0)
                this.openMail(this.mails[0].uid)
            },1000)
          }
        }
      }
    },
    async previous_letter(){
      const letters = await this.$store.getters["mails"];
      if(this.indexMail-2 >= 0 && this.indexMail-(this.page-1)*this.amount-1>0){
        if(letters[this.indexMail-(this.page-1)*this.amount-2]){
          this.indexMail--
          this.openMail(letters[this.indexMail-(this.page-1)*this.amount-1].uid)
        }
      } else if(this.indexMail-2 >= 0 && this.indexMail-(this.page-1)*this.amount-1===0){
        if(this.page > 1){
          this.page--
          if(this.isSearch){
            this.search_values['page'] = this.page
            this.isSearchPageChanged = true
            await this.search_mail(this.search_values)
            this.isSearchPageChanged = false
          } else await this.changePage()
          setTimeout(()=>{
            this.indexMail = this.page*this.amount
            const letters2 = this.$store.getters["mails"];
            if(this.mails.length > 0)
              this.openMail(letters2[this.amount-1].uid)
          },1000)
        }
      }
    },
    async sendCreateMail(actionPayload, isAutoSave){
      this.error = null;
      this.isLoading = true;
      try {
        let reallyOpen = this.isNewMailOpen
        let uid = await this.$store.dispatch("createMail", actionPayload);
        if(!isAutoSave){
          if(reallyOpen)
            this.isNewMailOpen = false
          if(this.isSendingMail){
            this.success=this.$t('msg_sent')
            const step = 2
            let interval = setInterval(()=>{
              this.widthProgressBar+=step
            },80)
            setTimeout(()=>{
              this.success=null
              clearInterval(interval)
              this.widthProgressBar = 0
              }, 4000)
          }
          const j = this.subfolders.findIndex(el => el.id === this.activeEmail)
          if(j!==-1 && this.subfolders[j].name){
            const arr = this.subfolders[j].name.split(".")
            if(arr.length > 1 && (arr[1] === "Drafts" || arr[1] === "Sent")){
              this.loadMails(this.activeEmail)
            }
          }

          const msg_id = this.$route.params.msg_id
          if(msg_id){
            if(this.mailIsOpen && this.opened_mail?.message?.uid)
              this.$router.push({name: 'message', params: {msg_id: this.opened_mail.message.uid}})
            else{
              // eslint-disable-next-line
              const {msg_id, params} = this.$route.params
              this.$router.push({name: 'folder', params: {...params}})
            }
          }
        } else{
          this.dataForCreateMail.uid = uid
          this.$router.push({name: 'message', params: {msg_id: uid}})
        }
      } catch (err) {
        this.errorHandler(err)
      }
      this.isSendingMail = false
      this.isLoading = false;
    },
    close_create_mail(isNotDraft, isAutoSave, payload){
      if(payload === null){
        this.isNewMailOpen = false
        return
      }
      this.email_to = ''
      if(isNotDraft){
        this.isSendingMail = true
        this.sendCreateMail(payload, false)
      }else{
        if(!isAutoSave){
          this.isNewMailOpen = false
          this.sendCreateMail(payload, false)
        } else{
          this.sendCreateMail(payload, isAutoSave)
        }
      }
    },
    selectMails(flag){
      const isCheckedMails = this.checked_mails.length === 0
      this.checked_mails = []
      this.isCheckedAllFolder = false
      this.firstCheckboxWithShift = -1
      this.firstUncheckedCheckboxWithShift = -1
      switch (flag) {
        case 'all_on_the_page':
          this.isCheckedALL = true
          this.mails.forEach(el => {
            this.checked_mails.push(el.uid)
          })
          break;
        case 'all':
          if(this.mails.length === 0)
            break;
          this.isCheckedALL = true
          this.isCheckedAllFolder = true
          this.mails.forEach(el => {
            this.checked_mails.push(el.uid)
          })
          break;
        case 'clear':
          this.isCheckedALL = false
          break;
        case 'read':
          this.mails.forEach(el => {
            if(el.flags){
              const i = el.flags.indexOf("seen")
              if(i!==-1){
                this.checked_mails.push(el.uid)
              }
            }
          })
          this.isCheckedALL = this.checked_mails.length > 0;
          break;
        case 'unread':
          this.mails.forEach(el => {
            if(el.flags){
              const i = el.flags.indexOf("seen")
              if(i===-1){
                this.checked_mails.push(el.uid)
              }
            }
          })
          this.isCheckedALL = this.checked_mails.length > 0;
          break;
        case 'marked':
          this.mails.forEach(el => {
            if(el.flags){
              const i = el.flags.indexOf("flagged")
              if(i!==-1){
                this.checked_mails.push(el.uid)
              }
            }
          })
          this.isCheckedALL = this.checked_mails.length > 0;
          break;
        case 'unmarked':
          this.mails.forEach(el => {
            if(el.flags){
              const i = el.flags.indexOf("flagged")
              if(i===-1){
                this.checked_mails.push(el.uid)
              }
            }
          })
          this.isCheckedALL = this.checked_mails.length > 0;
          break;
      }
      this.animateNotification(isCheckedMails)
    },
    animateNotification(isCheckedMails){
      if(this.checked_mails.length === 0) {
        this.sticky = false
        setTimeout(() => {
          this.showNotification = false
        }, 500)
      } else if(isCheckedMails) {
        this.showNotification = true
        setTimeout(() => {
          this.sticky = true
        }, 500)
      }
    },
    async remove_all(){
      const isCheckedMails = this.checked_mails.length > 0
      if(this.isRemoveAll)
        return
      this.isLoadingMails = true;
      this.isRemoveAll = true
      this.isLoading = true;
      if(this.controller){
        this.controller.abort()
        this.controller = null
      }
      this.controller = new AbortController()

      let actionPayload = {};
      const isSearch = await this.$store.getters['getSearch']
      const label_id = this.$route.query.label
      if (isSearch) {
        actionPayload = {
          payload: {
            request: this.search_values.request
          },
          controller: this.controller
        }
      } else if(this.activeEmail > 0){
        actionPayload = {
          folder_id: this.activeEmail
        }
      }else if(this.activeEmail === -1 && label_id){
        actionPayload = {
          payload: {
            label_id: +label_id
          },
          controller: this.controller
        };
        let alias = await this.$store.getters['getLeftSidebarSelect']
        if(alias && alias !== this.$t('all_messages') && this.account && this.account.aliases){
          const i = this.account.aliases.findIndex(el => el.email === alias)
          if(i !== -1){
            actionPayload.payload.request['alias_id'] = this.account.aliases[i].id
          } else if(alias === this.account.email){
            actionPayload.payload.request['alias_id'] = 0
          }
        }
      }
      try {
        if(this.activeEmail === -1 && label_id || isSearch)
          await this.$store.dispatch("removeSearch", actionPayload);
        else
          await this.$store.dispatch("removeAll", actionPayload);
        this.controller = null
        this.areYouSure = false
        this.isCheckedAllFolder = false
        this.isCheckedALL = false
        this.hideMailsList = false
        this.mailIsOpen = false
        this.boxSplit = false
        this.activeMail = -1
        this.subject = ''
        this.mailsItem = {}
        this.opened_mail = {}
        const newquery = Object.assign({}, this.$route.query);
        if(newquery['uid']){
          delete newquery['uid']
          this.$router.push({query: {...newquery}});
        }
        this.checked_mails = []
        this.firstCheckboxWithShift = -1
        this.firstUncheckedCheckboxWithShift = -1
        if(isSearch){
          const payload = {
            type: 'refresh',
            counter: Math.floor(Math.random() * 10000)
          }
          this.$store.dispatch('searchPagination', payload)
        }else if(this.activeEmail === -1 && label_id)
          this.getLabelMails(+label_id)
        else
          this.loadMails(this.activeEmail)
      }catch(err){
        this.error = err.message || "smth went wrong";
        this.widthPB()
      }
        this.isLoadingMails = false;
        this.isLoading = false;
        this.isRemoveAll = false
        if(isCheckedMails && this.checked_mails.length === 0)
          this.animateNotification(!isCheckedMails)
    },
    async all_folder_move(email){
      this.processing = true
      const isCheckedMails = this.mails.length > 0
      this.isLoading = true;
      this.isLoadingMails = true;
      if(this.controller3){
        this.controller3.abort()
        this.controller3 = null
      }
      this.controller3 = new AbortController()
      let actionPayload = {
        payload: {
          request_type: "move",
          request_params: {
            destination_id: email
          },
          request: {}
        },
        controller: this.controller3
      };
      const isSearch = await this.$store.getters['getSearch']
      if (isSearch) {
        actionPayload.payload.request = this.search_values.request
      } else{
        if(this.activeEmail > 0){
          actionPayload.payload.request['folder_id'] = this.activeEmail
        }else if(this.activeEmail === -1){
          const label_id = this.$route.query.label
          if(label_id){
            actionPayload.payload.request['label_id'] = +label_id
          }
        }
      }
      try {
        await this.$store.dispatch("moveAllFolder", actionPayload);
        this.controller3 = null
        this.hideMailsList = false
        if(isSearch){
          const payload = {
            type: 'refresh',
            counter: Math.floor(Math.random() * 10000)
          }
          this.$store.dispatch('searchPagination', payload)
        }else {
          if (this.activeEmail > 0) {
            this.loadMails(this.activeEmail, false, true)
          } else {
            const label_id = await this.$route.query.label
            if (label_id) {
              this.getLabelMails(+label_id, null, true)
            }
          }
        }
        if(this.opened_mail?.message?.uid){
          destroy_split()
          this.split_call = true
          this.mailIsOpen = false
          this.boxSplit = false
          this.activeMail = -1
          this.subject = ''
          this.mailsItem = {}
          this.opened_mail = {}
          const newquery = Object.assign({}, this.$route.query);
          if(newquery['uid']){
            delete newquery['uid']
            this.$router.push({query: {...newquery}});
          }
        }
        this.isCheckedAllFolder = false
        this.isCheckedALL = false
        this.checked_mails = []
        this.firstCheckboxWithShift = -1
        this.firstUncheckedCheckboxWithShift = -1
      }catch(err){
        this.error = err.message || "smth went wrong";
        this.widthPB()
      }
      this.isLoadingMails = false;
      this.isLoading = false;
      if(isCheckedMails && this.checked_mails.length === 0)
        this.animateNotification(!isCheckedMails)
      this.processing = false
    },
    async move_to(email, dragged_uid, only_one){
      if(this.isCheckedAllFolder){
        this.all_folder_move(email)
        return
      }
      const isCheckedMails = this.checked_mails.length > 0
      this.processing = true
      this.isLoading = true;
      this.isLoadingMails = true;
      let new_uids = [];
      if(!dragged_uid && !only_one){
        new_uids = this.checked_mails.slice()
        if(this.checked_mails.length === 0 && this.mailIsOpen)
          new_uids.push(this.activeMail)
      } else{
        if(!only_one)
          new_uids = this.checked_mails.slice()
        new_uids.push(dragged_uid)
      }
      if(only_one)
        new_uids.push(dragged_uid)
      let actionPayload = {
        uids: new_uids,
        payload: {
          destination_id: email,
          folder_messages: []
        }
      };
      const isSearch = await this.$store.getters['getSearch']
      if(this.activeEmail > 0 && !isSearch){
          actionPayload.payload['folder_messages'].push({
            folder_id: this.activeEmail,
            message_uids: new_uids
          })
      }else{
        let folder_messages = []
        let temp = {}
        new_uids.forEach(el => {  
          const k = this.mails.findIndex(mail => el === mail.uid)
          if(k!==-1){
            if(temp[this.mails[k].folder_id] && temp[this.mails[k].folder_id].length > 0)
              temp[this.mails[k].folder_id].push(el)
            else 
              temp[this.mails[k].folder_id] = [el]
          }
        })
        Object.keys(temp).forEach(key => {
          folder_messages.push({
            folder_id: +key,
            message_uids: temp[key]
          })
        })
        actionPayload.payload['folder_messages'] = folder_messages
      }
      try {
        await this.$store.dispatch("moveToFolder", actionPayload);
        this.removeMailsFromMailsList(new_uids)
        if(this.hideMailsList){
          this.hideMailsList = false
        }
        if(this.activeEmail > 0){
          if(isSearch){
            const payload = {
              type: 'refresh',
              counter: Math.floor(Math.random() * 10000)
            }
            this.$store.dispatch('searchPagination', payload)
          }else
            this.loadMails(this.activeEmail, false, true)
        } else{
          if(isSearch){
            const payload = {
              type: 'refresh',
              counter: Math.floor(Math.random() * 10000)
            }
            this.$store.dispatch('searchPagination', payload)
          }else{
            const label_id = await this.$route.query.label
            if(label_id){
              this.getLabelMails(+label_id, null, true)
            }
          }
        }
        if(this.opened_mail && this.opened_mail.message && this.opened_mail.message.uid){
          const i = new_uids.indexOf(this.opened_mail.message.uid)
          if(i!==-1){
            destroy_split()
            this.split_call = true
            this.mailIsOpen = false
            this.boxSplit = false
            this.activeMail = -1
            this.subject = ''
            this.mailsItem = {}
            this.opened_mail = {}
            const newquery = Object.assign({}, this.$route.query);
            if(newquery['uid']){
              delete newquery['uid']
              this.$router.push({query: {...newquery}});
            }
          }
        }
        if(!only_one)
          this.checked_mails = []
        else {
          new_uids.forEach(el => {
            const k = this.checked_mails.indexOf(el)
            if(k !== -1)
                this.checked_mails.splice(k, 1)
          })
        }
        this.firstCheckboxWithShift = -1
        this.firstUncheckedCheckboxWithShift = -1
      }catch(err){
        this.error = err.message || "smth went wrong";
        this.widthPB()
      }
      this.isLoadingMails = false;
      this.isLoading = false;
      if(isCheckedMails && this.checked_mails.length === 0)
        this.animateNotification(!isCheckedMails)
      if(this.checked_mails.length === 0)
        this.isCheckedALL = false
      this.processing = false
    },
    async markAllFolder(flag){
      this.isLoading = true;
      this.isLoadingMails = true;
      if(this.controller3){
        this.controller3.abort()
        this.controller3 = null
      }
      this.controller3 = new AbortController()
      let actionPayload = {
        payload: {
          request_type: "mark",
          request_params: {
            flag: flag
          },
          request: {}
        },
        controller: this.controller3
      };
      const isSearch = await this.$store.getters['getSearch']
      if (isSearch) {
        actionPayload.payload.request = this.search_values.request
      } else{
        if(this.activeEmail > 0){
          actionPayload.payload.request['folder_id'] = this.activeEmail
        }else if(this.activeEmail === -1){
          const label_id = this.$route.query.label
          if(label_id){
            actionPayload.payload.request['label_id'] = +label_id
          }
        }
      }
      this.mails.forEach(el => {
          if(flag === 'seen'){
            const index = el.flags.indexOf("seen")
            if(index === -1){
              el.flags.push("seen")
            }
          } else if(flag === 'unseen'){
            const index = el.flags.indexOf("seen")
            if(index !== -1){
              el.flags.splice(index, 1)
            }
          } else if(flag === 'flagget'){
            const index = el.flags.indexOf("flagged")
            if(index === -1){
              el.flags.push("flagged")
            }
          } else if(flag === 'unflagget'){
            const index = el.flags.indexOf("flagged")
            if(index !== -1){
              el.flags.splice(index, 1)
          }
        }
      })
      if(this.opened_mail?.message?.uid){
        const i = this.mails.findIndex(el => el.uid === this.opened_mail.message.uid)
        if (i !== -1) {
          this.opened_mail.message.flags = this.mails[i].flags
          this.mailsItem.flags = this.mails[i].flags
        }
      }
      try {
        await this.$store.dispatch("markAllFolder", actionPayload);
        this.controller3 = null
      }catch(err){
        this.error = err.message || "smth went wrong";
        this.widthPB()
        this.mails.forEach(el => {
          if (flag === 'flagget') {
            const index = el.flags.indexOf("flagged")
            if (index !== -1) {
              el.flags.splice(index, 1)
            }
          } else if (flag === 'unflagget') {
            const index = el.flags.indexOf("flagged")
            if (index === -1) {
              el.flags.push("flagged")
            }
          } else if(flag === 'seen'){
            const index = el.flags.indexOf("seen")
            if(index !== -1){
              el.flags.splice(index, 1)
            }
          } else if(flag === 'unseen'){
            const index = el.flags.indexOf("seen")
            if(index === -1){
              el.flags.push("seen")
            }
          }
        })
        if(this.opened_mail?.message?.uid){
          const i = this.mails.findIndex(el => el.uid === this.opened_mail.message.uid)
          if (i !== -1) {
            this.opened_mail.message.flags = this.mails[i].flags
            this.mailsItem.flags = this.mails[i].flags
          }
        }
      }
      this.isLoadingMails = false;
      this.isLoading = false;
    },
    mark_as_read(flag, label_id){
      if(this.isCheckedAllFolder){
        this.markAllFolder(flag)
        return
      }
      let new_uids = this.checked_mails.slice()
      if(this.activeMail !== -1){
        const i = this.checked_mails.indexOf(this.activeMail)
        if(i===-1){
          new_uids.push(this.activeMail)
        }
      }
      if(label_id){
        this.mark(flag, new_uids, label_id)
      } else{
        this.mark(flag, new_uids)
      }
    },
    async refresh(notScrollUp){
      this.isSearch = false
      this.search_values = {}
      const label_id = this.$route.params.l_id
      const route_name = this.$route.name
      if(route_name === 'search' || route_name === 'search_message'){
        this.refresh_search_counter++
        const payload = {
          type: 'refresh',
          counter: this.refresh_search_counter
        }
        this.$store.dispatch('searchPagination', payload)
      }else if(label_id){
        this.getLabelMails(+label_id)
      }else{
        if(notScrollUp) {
          await this.loadMails(this.activeEmail, true, true)
        }
        else{
          if(!this.isCreated) {
            await this.loadMails(this.activeEmail)
            this.selectMails('clear')
          }
        }
      }
    },
    just_select_mail(uid, l){
      const i = this.checked_mails.indexOf(uid)
      const isCheckedMails = this.checked_mails.length === 0
      const el = document.getElementById('chain-button-'+this.mails[l].uid)
      if(i !== -1) {
        if (this.isCheckedAllFolder) {
          this.selectMails('clear')
          this.selectMails('all_on_the_page')
        }
        this.checked_mails.splice(i, 1)

        if(el && el.classList.contains('collapsed')){
          this.mails[l].threads.forEach(el => {
            const j = this.checked_mails.indexOf(el.uid)
            j !== -1 && this.checked_mails.splice(j, 1)
          })
        }
      } else {
        this.checked_mails.push(uid)
        if(el && el.classList.contains('collapsed')){
          this.mails[l].threads.forEach(el => {
            const j = this.checked_mails.indexOf(el.uid)
            j === -1 && this.checked_mails.push(el.uid)
          })
        }
      }
      this.animateNotification(isCheckedMails)
    },
    select_mail(uid, isShiftKey){
      const l = this.mails.findIndex(el => el.uid === uid)
      if(l!==-1 && this.mails[l].threads){
        this.just_select_mail(uid, l)
        return
      }
      const isCheckedMails = this.checked_mails.length === 0
      const i = this.checked_mails.indexOf(uid)
      if(i !== -1){
        if(this.isCheckedAllFolder){
          this.selectMails('clear')
          this.selectMails('all_on_the_page')
        }
        this.checked_mails.splice(i, 1)
        if(isShiftKey && this.firstUncheckedCheckboxWithShift !== -1){
          let first = this.mails.findIndex(el => el.uid === this.firstUncheckedCheckboxWithShift)
          let last = this.mails.findIndex(el => el.uid === uid)
          if(first !== -1 && last !== -1){
            if(first > last+1){
              for(let j=last+1; j<first; j++){
                const index = this.checked_mails.indexOf(this.mails[j].uid)
                if(index !== -1){
                  this.checked_mails.splice(index, 1)
                }
              }
            } else if(last > first+1){
              for(let j=first+1; j<last; j++){
                const index = this.checked_mails.indexOf(this.mails[j].uid)
                if(index !== -1){
                  this.checked_mails.splice(index, 1)
                }
              }
            }
          }
        }
        this.firstUncheckedCheckboxWithShift = uid
      } else{
        if(isShiftKey && this.checked_mails.length > 0){
          this.firstUncheckedCheckboxWithShift = -1

          let first = this.mails.findIndex(el => el.uid === this.checked_mails[this.checked_mails.length-1])
          let last = this.mails.findIndex(el => el.uid === uid)
          if(first !== -1 && last !== -1){
            if(first > last+1){
              for(let j=last+1; j<first; j++){
                const index = this.checked_mails.indexOf(this.mails[j].uid)
                if(index === -1){
                  this.checked_mails.push(this.mails[j].uid)
                }
              }
            } else if(last > first+1){
              for(let j=first+1; j<last; j++){
                const index = this.checked_mails.indexOf(this.mails[j].uid)
                if(index === -1){
                  this.checked_mails.push(this.mails[j].uid)
                }
              }
            }
          }
        }
        this.checked_mails.push(uid)
      }
      this.animateNotification(isCheckedMails)
    },
    isChecked(uid){
      const i = this.checked_mails.indexOf(uid)
      if(i !== -1){
        return true
      } else{
        return false
      }
    },
    async search_mail(values, type){
      this.isEmptyMailsList = false
      this.isLoading = true;
      this.isLoadingMails = true;
      if(this.controller){
        this.controller.abort()
        this.controller = null
      }
      values['request']['limit'] = this.amount
      this.controller = new AbortController()
      const actionPayload = {
        payload: {...values},
        controller: this.controller
      };
      if(this.$route.query['uid']){
        const newquery = Object.assign({}, this.$route.query);
        delete newquery['uid']
        this.$router.push({query: {...newquery}});
      }
      if(!this.isSearchPageChanged && !(type && type ==='refresh')) this.activeMail = -1
      if(this.hideMailsList && !this.isSearchPageChanged){
        this.hideMailsList = false
      }
      if(this.mailIsOpen && !(type && type ==='refresh')){
        destroy_split()
        this.split_call = true
      }
      if(!this.isSearchPageChanged && !(type && type ==='refresh')){
        this.mailIsOpen = false
        this.subject = ''
        this.mailsItem = {}
        this.opened_mail = {}
      }
      if(!(type && type ==='refresh'))
        this.boxSplit = false
      if(!(type && type ==='refresh'))
        this.selectMails('clear')
      try {
        await this.$store.dispatch('getLabelMails', actionPayload)   
        this.controller = null
        if(!this.isSearchPageChanged){ 
          this.hideMailsList = false
          this.indexMail = -1
        }
        this.mails = []
        const letters = this.$store.getters["mails"];
        let lettersFinal = []
        letters.forEach(letter => {
          lettersFinal.push(letter)
          if(letter.threads && letter.threads.length > 0){
            letter.threads.forEach(el =>{
              el['isChainLetter'] = true
              lettersFinal.push(el)
            })
          }
        })
        this.mails = lettersFinal
        this.page = values.request['page']
        this.total = this.$store.getters["getTotal"];
        this.all = this.$store.getters["getAll"];
        this.pages_amount = Math.ceil(this.total / this.amount);
        this.isSearch = true
        this.search_values = values
        if(this.mails.length === 0)
          this.isEmptyMailsList = true

        const msg_id = +this.$route.params.msg_id  
        if(msg_id){
          const i = this.mails.findIndex(el => el.uid === msg_id)
          if(i!==-1){
            this.activeMail = msg_id
            this.subject = this.mails[i].subject
            this.mailsItem = this.mails[i]
            this.mailIsOpen = true
            this.boxSplit = true
            if(this.isMobile){
              this.hideMailsList = true
              this.indexMail = (this.page-1)*this.amount+ i+1;
            }
          }
        }
        if(this.isCreated)
          this.isCreated = false
      }catch(err){
        this.errorHandler(err)
      }
      this.isLoadingMails = false;
      this.isLoading = false;
    },
    open_folder(id){
      this.page = 1
      this.activeEmail = id
      if(this.$route.query['uid']){
        const newquery = Object.assign({}, this.$route.query);
        delete newquery['uid']
        this.$router.push({query: {...newquery}});
      }
      this.activeMail = -1
      if(this.hideMailsList)
        this.hideMailsList = false
      this.$router.push({name: 'folder', params: {a_id: this.$route.params.a_id, f_id: id, page: this.page}})
      this.mailIsOpen = false
      this.boxSplit = false
      this.subject = ''
      this.mailsItem = {}
      this.opened_mail = {}
    },
    setActive(name, id){
      this.counter_click_folder++
      this.isSearch = false
      this.search_values = {}
      this.active = id
      this.activeEmail = id
      this.selectMails('clear')
      if(this.controller){
        this.controller.abort()
        this.controller = null
      }
      destroy_split()
      this.split_call = true
      if(id > 0 && name !== 'labels'){
        const j = this.subfolders.findIndex(el => el.id === id)
        if(j!==-1 && this.subfolders[j].name){
          const arr = this.subfolders[j].name.split(".")
          if(arr.length > 1 && arr[1] === "Drafts"){
            this.split_call = false
          }
        }
      } 
      if(name !== 'labels'){
        const msg_id = +this.$route.params.msg_id
        const page = +this.$route.params.page
        if(page)
          this.page = page
        else
          this.page = 1
        this.activeEmail = id
        if(!msg_id) {
          this.activeMail = -1
          if (this.hideMailsList)
            this.hideMailsList = false
          this.mailIsOpen = false
          this.boxSplit = false
          this.subject = ''
          this.mailsItem = {}
          this.opened_mail = {}
        }
      }
    },
    fullScreenLetter2(i, uid){
      let message_preview = true
      if(this.account?.settings)
        message_preview = this.account.settings.message_preview
      const j = this.subfolders.findIndex(el => el.id === this.activeEmail)
      if(message_preview && !this.isMobile){
        this.clicks++;
        if (this.clicks === 1) {
          this.first_click_uid = uid
          this.timer = setTimeout( () => {
            this.clicks = 0
          }, this.delay);
        } else {
          if(this.first_click_uid === uid){
            clearTimeout(this.timer);  
            this.first_click_uid = 0
            this.clicks = 0;
            if(j!==-1 && this.subfolders[j].name){
              const arr = this.subfolders[j].name.split(".")
              // eslint-disable-next-line
              if(arr.length > 1 && arr[1] === "Drafts"){
              } else{
                this.fullScreenLetter(i)
              }
            }
          }else{
            clearTimeout(this.timer);  
            this.first_click_uid = uid
            this.clicks = 1
            this.timer = setTimeout( () => {
              this.clicks = 0
            }, this.delay);
          }
        }
      }else{
        if(j!==-1 && this.subfolders[j].name){
          const arr = this.subfolders[j].name.split(".")
          // eslint-disable-next-line
          if(!(arr.length > 1 && arr[1] === "Drafts")){
            this.fullScreenLetter(i)
          }
        }
      }
    },
    fullScreenLetter(i){
      this.indexMail = (this.page-1)*this.amount+ i+1;
      this.hideMailsList = true
      this.mailIsOpen = true
      this.boxSplit = false
      destroy_split()
      this.split_call = true
    },
    full_screen_mail(val){
      const msg_id = +this.$route.params.msg_id
      if(val && msg_id){
        const i = this.mailsFinal.findIndex(el => el.uid === msg_id)
        if(i!==-1){
          this.indexMail = (this.page-1)*this.amount+ i+1;
          this.hideMailsList = true
          this.mailIsOpen = true
          this.boxSplit = false
          destroy_split()
          this.split_call = true
        }
      } else if(!val){
        this.hideMailsList = false
        this.mailIsOpen = true
        this.boxSplit = true
        this.indexMail = -1
        if(this.split_call){
          split_js()
          this.split_call = false
        }
      }
    },
    returnToMailslist(){
      this.hideMailsList = false
      this.indexMail = -1;
    },
    async openMail(id) {
      if(this.controller2){
        this.controller2.abort()
        this.controller2 = null
      }
      const i = this.mails.findIndex(el=> el.uid === id)
      if(i===-1){
        const route_name = this.$route.name
        let name = 'folder'
        if(route_name === 'search_message')
          name = 'search'
        else if (route_name === 'label_message')
          name = 'label'
        // eslint-disable-next-line
        const {msg_id, params} = this.$route.params
        this.$router.push({name: name, params: {...params}})
        return
      }
      this.activeMail = id;
      if(this.isMobile)
        this.hideMailsList = true
      const j = this.mails[i].flags.findIndex(el => el === 'seen')
      if(j === -1)
        this.mails[i].flags.push("seen")
      this.subject = this.mails[i].subject
      this.mailsItem = this.mails[i]
      this.mailIsNotLoading = true
      const route_name = this.$route.name
      if(this.activeEmail > 0){
        const j = this.subfolders.findIndex(el => el.id === this.activeEmail)
        if(j!==-1 && this.subfolders[j].name){
          const arr = this.subfolders[j].name.split(".")
          if(arr.length > 1 && arr[1] === "Drafts"){
            this.split_call = false
            this.loadMail(id, true);
          } else{
            this.mailIsOpen = true;
            if(route_name !== 'search' && route_name !== 'search_message') {
              this.$router.push({name: 'message', params: {msg_id: id}})
            }
            if(!this.hideMailsList) this.boxSplit = true
          }
        }
      } else {
        this.mailIsOpen = true;
        if(route_name === 'search' || route_name === 'search_message')
          this.$router.push({name: 'search_message', params: {msg_id: id}})
        else
          this.$router.push({name: 'label_message', params: {msg_id: id}})
        if(!this.hideMailsList) this.boxSplit = true
      }
    },
    async loadMail(id, isDraft=false, isShowingImages=false){
      this.opened_mail = {}
      this.isLoading = true;
      if(this.controller2){
        this.controller2.abort()
        this.controller2 = null
      }
      this.controller2 = new AbortController()
      let actionPayload = {
        controller: this.controller2
      };
      const i = this.mails.findIndex(el => el.uid === id)
      if(i!==-1 && this.mails[i] && this.mails[i].flags){
        const m = this.mails[i].flags.indexOf("seen")
        if(m===-1){
          this.mails[i].flags.push("seen")
        }
      }
      const isSearch = await this.$store.getters['getSearch']
      if(this.activeEmail > 0 && !isSearch){
        actionPayload['path'] = this.activeEmail+'/'+id
      } else {
        if(i!==-1){
          actionPayload['path'] = this.mails[i].folder_id +'/'+id
        }
      }
      if(!!actionPayload['path'] && this.image_display === 'always' && !isShowingImages){
        actionPayload['path'] += "?show_images=true"
        this.show_images=true
      }else if(!!actionPayload['path'] && this.image_display === 'never' && !isShowingImages)
        this.show_images=false
      try {
        const call_split = this.split_call && !isDraft && !this.hideMailsList
        if(call_split){
          this.split_call = false
        }
        this.mailIsLoadedInFirstLoad = true
        if(!!actionPayload['path'] && !isShowingImages && this.image_display === 'correspondent' && i!==-1 && this.mails[i].fields
         && this.mails[i].fields.from && this.mails[i].fields.from.length > 0 && this.mails[i].fields.from[0].email){
          const str = this.mails[i].fields.from[0].email
          const payload = {
            search: str,
            controller: null
          };
          const resp = await this.$store.dispatch("getContactsList2", payload);
          if(resp && resp.contacts && resp.contacts.length > 0){
            actionPayload['path'] += "?show_images=true"
            this.show_images=true
          }else{
            this.show_images=false
          }
        }
        if(isShowingImages){
          actionPayload['path'] += "?show_images=true"
          this.show_images=true
        }
        await this.$store.dispatch("getMail", actionPayload);
        this.controller2 = null
        this.opened_mail = this.$store.getters["opened_mail"]
        if(isDraft){
          this.dataForCreateMail = {...this.opened_mail.message}
          this.dataForCreateMail['isDraft']=true
          if(this.isNewMailOpen){
            this.isNewMailOpen = false
          } 
          setTimeout(()=>{
            this.isNewMailOpen = true;
          },200)
        }
        this.mailIsNotLoading = false
      }catch (err) {
        if(err && err.response  && err.response.status && err.response.status === 404){
          const newquery = Object.assign({}, this.$route.query);
          if(newquery['message_id']){
            delete newquery['message_id']
            this.$router.push({query: {...newquery}});
          }
          const {msg_id, params} = this.$route.params
          if(msg_id){
            const route_name = this.$route.name
            let name = 'folder'
            if(route_name === 'search_message')
              name = 'search'
            else if (route_name === 'label_message')
              name = 'label'
            this.$router.push({name: name, params: {...params}})
          }
        } else{
          this.errorHandler(err)
        }
        this.closeMail()
        this.activeMail = -1
      }
      this.isLoading = false;
    },
    closeMail() {
      if(this.controller2){
        this.controller2.abort()
        this.controller2 = null
      }
      this.mailIsOpen = false;
      this.boxSplit = false
      this.opened_mail = {}
      this.subject = ''
      this.mailsItem = {}
      this.hideMailsList = false
      this.indexMail = -1
      this.activeMail = -1
      destroy_split()
      this.split_call = true
    },
    create_mail(isEmpty, toAll) {
      this.toAll = toAll
      if(this.isNewMailOpen){
        this.$refs.createMailComponent.fullscreenCreateMail()
        return
      }
      if(isEmpty)
        this.dataForCreateMail = {}
      else 
        this.dataForCreateMail = {...this.opened_mail.message}
      this.isNewMailOpen = true;
    },
    create_new_mail_with_email(email){
      this.email_to = email
      this.toAll = 0
      this.dataForCreateMail = {}
      this.isNewMailOpen = true;
    },
    openOutbox(mails) {
      this.mails = mails;
      this.isNewMailOpen = true;
    },
    async loadMails(id, isNextPage, notScrollUp) {
      if(!this.isCheckedAllFolder){
        if(!isNextPage && !notScrollUp){
          this.checked_mails = []
          this.isCheckedALL = false
        }
      }
      this.isEmptyMailsList = false
      this.isLoadingMails = true;
      if(!this.hideMailsList){
        this.hideMailsList = false
        this.indexMail = -1
      }
      if(this.controller){
        this.controller.abort()
        this.controller = null
      }
      this.controller = new AbortController()
      let actionPayload = {   
        payload: {
          request_type: "get",
          request: {
            page: this.page,
            limit: this.amount,
          } 
        },
        controller: this.controller
      };
      if(id === 0){
        actionPayload.payload.request['flags'] = ["flagged"]
        const k = this.subfolders.findIndex(el => el.name === "INBOX")
        if(k!==-1)
          actionPayload.payload.request['folder_id'] = this.subfolders[k].id
      }else if(id > 0)
        actionPayload.payload.request['folder_id'] = id
      let alias = await this.$store.getters['getLeftSidebarSelect']
      if(alias && alias !== this.$t('all_messages') && this.account?.aliases){
        const i = this.account.aliases.findIndex(el => el.email === alias)
        if(i !== -1)
          actionPayload.payload.request['alias_id'] = this.account.aliases[i].id
        else if(alias === this.account.email)
          actionPayload.payload.request['alias_id'] = 0
      }
      try {
        await this.$store.dispatch('getLabelMails', actionPayload)   
        this.controller = null
        const letters = await this.$store.getters["mails"];
        if(letters.length === 0){
          if(this.page > 1){
            this.previous()
            return
          }
        }
        let lettersFinal = []
        letters.forEach(letter => {
          lettersFinal.push(letter)
          if(letter.threads && letter.threads.length > 0){
            letter.threads.forEach(el =>{
              el['isChainLetter'] = true
              lettersFinal.push(el)
            })
          }
        })
        this.mails = lettersFinal
        const mails_list_tag = document.getElementById("mails_id")
        if(mails_list_tag && !notScrollUp){
          mails_list_tag.scrollTo(0,0)
        }
        if(this.isCreated){
          const i = this.mails.findIndex(el => el.uid === this.activeMail)
          if(i!==-1){
            this.subject = this.mails[i].subject
            this.mailsItem = this.mails[i]
            if(this.isMobile){
              this.hideMailsList = true
              this.indexMail = (this.page-1)*this.amount+ i+1;
            }
          }
          this.isCreated = false
        }
        const msg_id = +this.$route.params.msg_id
        if(msg_id && this.activeMail !== msg_id){
          const j = this.mails.findIndex(el=> el.uid === msg_id)
          if(j===-1) {
            this.$router.push({
              name: 'folder',
              params: {a_id: this.$route.params.a_id, f_id: this.activeEmail, page: this.page}
            })
          }
          else {
            this.openMail(msg_id)
          }
        }
        if(this.mails.length === 0)
          this.isEmptyMailsList = true
        this.total = this.$store.getters["getTotal"];
        this.all = this.$store.getters["getAll"];
        this.pages_amount = Math.ceil(this.total / this.amount);
        const lang = this.$store.getters['getLanguage']
        if(lang)
          this.lang = lang
        if(this.isCheckedAllFolder)
          this.selectMails('all')
      } catch (err) {
        console.log('err :>> ', err);
        if(err?.response?.data?.error?.meta?.code === 400 && err?.response?.data?.error?.message === "request_folder_id_error"){
          this.active = this.folders[0].id
          this.activeEmail = this.active;
          this.page = 1
          this.$router.push({name: 'folder', params: {a_id: 'all', f_id: this.active, page: this.page}})
        } else
          this.errorHandler(err)
      }
      this.isLoadingMails = false;
    },
    async next(){
      if(this.page < this.pages_amount){
        this.page++
        await this.changePage()
      }
    },
    async previous(){
      if(this.page > 1){
        this.page--
        await this.changePage()
      }
    },
    async changePage(){
        const msg_id = +this.$route.params.msg_id
        const f_id = +this.$route.params.f_id
        const l_id = +this.$route.params.l_id
        let name = ''
        const params = {
          page: this.page,
        }
        if(f_id){
          params['f_id']=f_id
          name = 'folder'
        } else if(l_id) {
          params['l_id']=l_id
          name = 'label'
        }
        if(msg_id){
          params['msg_id']=msg_id
          name = 'message'
          if(l_id) 
            name = 'label_message'
        }
        await this.$router.push({name: name, params: params})
    },
    getAllFolders(folder){
      if(folder?.subfolders){
        this.subfolders = this.subfolders.concat(folder.subfolders);
        folder.subfolders.forEach(el => {
          this.getAllFolders(el)
        })
      }
    },
    async getSettings(){
      this.isLoading = true;
      try {
        await this.$store.dispatch("getSettings");
        this.settings = this.$store.getters['getUserSettings']
        this.amount = this.settings.per_page
        this.loadFolders()
      }catch (err) {
        this.errorHandler(err)
      }
      this.isLoading = false;
    },
    async loadFolders(updateMailboxList) {
      this.isLoading = true;
      this.isLoadingFolders = true;
      if(this.controllerFolders){
        this.controllerFolders.abort()
        this.controllerFolders = null
      }
      this.controllerFolders = new AbortController()
      const actionPayload = {
        controller: this.controllerFolders
      };
      try {
        await this.$store.dispatch("getFolders", actionPayload);
        this.controllerFolders = null
        this.folders = this.$store.getters["folders"];
        this.subfolders = this.folders.slice()
        this.getAllFolders(this.folders[0])
        if(updateMailboxList){
          if(this.activeEmail === -1){
            if(!this.$route.query.label){
              this.active = this.folders[0].id
              this.activeEmail = this.active;
            }
          }else{
            this.active = this.activeEmail
          }
        } else{
          const msg_id = +this.$route.params.msg_id
          if(this.activeEmail === -1){
            const name = this.$route.name
            if(name === 'mail'){
              this.active = this.folders[0].id
              this.activeEmail = this.active;
              const userAlias = this.$store.getters['user'].Info?.alias
              let a_id = 'all'
              if(userAlias){
                const aliases = this.$store.getters['getAccount'].aliases
                const k = aliases?.findIndex(el => el.email === userAlias)
                if(k!==-1){
                  a_id = aliases[k].id
                }
              }
              this.$router.push({name: 'folder', params: {a_id: a_id, f_id: this.active, page: this.page}})
              if(msg_id) {
                this.chooseMailForLoading(msg_id)
              }
            }
          } else{
            this.active = this.activeEmail
            if(msg_id)
              this.chooseMailForLoading(msg_id)
          }
        }
      } catch (err) {
        this.errorHandler(err)
      }
      this.isLoadingFolders = false;
      this.isLoading = false;
    },
    chooseMailForLoading(msg_id){
      const j = this.subfolders.findIndex(el => el.id === this.activeEmail)
      if(this.isMobile)
        this.activeMail = msg_id
      if(j!==-1 && this.subfolders[j].name){
        const arr = this.subfolders[j].name.split(".")
        if(arr.length > 1 && arr[1] === "Drafts")
          this.loadMail(msg_id, true);
        else{
          this.mailIsLoadedInFirstLoad = false
          let message_preview = true
          if(this.account?.settings)
            message_preview = this.account.settings.message_preview
          const i = this.mails.findIndex(el => el.uid === +this.$route.params.msg_id)
          if(!message_preview && i!==-1)
            this.fullScreenLetter(i)
          else{
            this.mailIsOpen = true
            this.boxSplit = true
          }
          this.mailIsNotLoading = true
        }
      }
    },
    folderName(){
      const j = this.subfolders.findIndex(el => el.id === this.activeEmail)
      return j!==-1 ? this.getFolderName(this.subfolders[j]) : ''
    },
    getFolderName(folder){
      if(folder.name === "INBOX")
        return this.$t(folder.name)
      if(folder.system){
        const i = this.flags.findIndex(el => folder.system === el)
        if(i !== -1)
          return this.$t(this.flags[i])
      }
      if(folder.isCollector){
        return folder.name
      }
      const arr = folder.name.split('.') 
      if(arr.length > 0)
        return arr[arr.length-1]
      return folder.name
    },
    async getLabelMails(label_id, uid, not_scroll_up){
      this.isEmptyMailsList = false
      this.isLoading = true
      this.isLoadingMails = true
      this.hideMailsList = false
      if(!not_scroll_up){
        this.isCheckedALL = false
        this.checked_mails = []
      }
      let action_payload = {
        payload: {
          request_type: "get",
          request: {
          label_id: label_id,
          page: this.page,
          limit: this.amount}
        }
      }
      let alias = await this.$store.getters['getLeftSidebarSelect']
      if(alias && alias !== this.$t('all_messages') && this.account && this.account.aliases){
        const i = this.account.aliases.findIndex(el => el.email === alias)
        if(i !== -1)
          action_payload.payload.request['alias_id'] = this.account.aliases[i].id
        else if(alias === this.account.email)
          action_payload.payload.request['alias_id'] = 0
      }
      try {
        await this.$store.dispatch('getLabelMails', action_payload)   
        const letters = this.$store.getters["mails"];
        let lettersFinal = []
        letters.forEach(letter => {
          lettersFinal.push(letter)
          if(letter.threads && letter.threads.length > 0){
            letter.threads.forEach(el =>{
              el['isChainLetter'] = true
              lettersFinal.push(el)
            })
          }
        })
        this.mails = lettersFinal
        this.isLoadingMails = false
        const mails_list_tag = document.getElementById("mails_id")
        if(mails_list_tag && !not_scroll_up)
          mails_list_tag.scrollTo(0,0)
        if(uid)
          console.log('uid :>> ', uid);
        if(this.isCreated){
          const i = this.mails.findIndex(el => el.uid === this.activeMail)
          if(i!==-1){
            this.subject = this.mails[i].subject
            this.mailsItem = this.mails[i]
            if(this.isMobile){
              this.hideMailsList = true
              this.indexMail = (this.page-1)*this.amount+ i+1;
            }
          }
          this.isCreated = false
        }
        const msg_id = +this.$route.params.msg_id
        if(msg_id){
          const j = this.mails.findIndex(el=> el.uid === msg_id)
          if(j===-1)
            this.$router.push({name: 'label', params: {a_id: this.$route.params.a_id, l_id: label_id, page: this.page}})
          else
            this.openMail(msg_id)
        }
        if(this.mails.length === 0)
          this.isEmptyMailsList = true
        this.total = this.$store.getters["getTotal"];
        this.all = this.$store.getters["getAll"];
        this.pages_amount = Math.ceil(this.total / this.amount);
        this.activeEmail = -1
        if(msg_id){
          const k = this.mails.findIndex(el => el.uid === msg_id)
          if(k!==-1){
            this.subject = this.mails[k].subject
            this.mailsItem = this.mails[k]
            this.activeMail = msg_id;
            if(this.isCreated){
              if(this.isMobile){
                this.hideMailsList = true
                this.indexMail = (this.page-1)*this.amount+ k+1;
              }
              this.isCreated = false
            }
            this.mailIsNotLoading = true
            let j = this.subfolders.findIndex(el => el.id === this.mails[k].folder_id)
            if(j!==-1 && this.subfolders[j].name){
              const arr = this.subfolders[j].name.split(".")
              if(arr.length > 1 && arr[1] === "Drafts")
                this.loadMail(msg_id, true);
              else{
                let message_preview = true
                if(this.account && this.account.settings)
                  message_preview = this.account.settings.message_preview
                const i = this.mails.findIndex(el => el.uid === +this.$route.query.uid)
                if(!message_preview && i!==-1)
                  this.fullScreenLetter(i)
                else{
                  this.mailIsOpen = true
                  this.boxSplit = true
                }
                this.mailIsNotLoading = true
              }
            }
          } else if(!this.mailIsOpen)
            this.$router.push({name: 'label', params: {a_id: this.$route.params.a_id, l_id: label_id, page: this.page}})
        }
        const lang = this.$store.getters['getLanguage']
        if(lang)
          this.lang = lang
      } catch (err) {
        this.errorHandler(err)
        this.isLoadingMails = false
      } 
      this.isLoading = false;
    },
    async delete_mail2(){
      if(this.isCheckedAllFolder){
        const label_id = this.$route.query.label
        const isSearch = await this.$store.getters['getSearch']
        if(isSearch)
          this.folder_name = ''
        else if(this.activeEmail > 0){
          const j = this.subfolders.findIndex(el => el.id === this.activeEmail)
          if(j!==-1)
            this.folder_name = this.getFolderName(this.subfolders[j])
        }else if(this.activeEmail===-1 && label_id){
          const t = this.labels.findIndex(el => el.id === +label_id)
          if(t!==-1)
            this.folder_name = this.labels[t].name
        }
        this.areYouSure = true
      }else{
        const hasChains = this.checked_mails.some(elem => {
          const i = this.mails.findIndex(el => el.uid === elem)
          if(i>=0 && this.mails[i].threads && this.mails[i].threads.length > 0)
            return true
          return false
        })
        if(hasChains)
          this.areYouSure2 = true
        else
          this.delete_mail(null)
      }
    },
    delete_mail3(uid){
      const i = this.mails.findIndex(el => el.uid === uid)
      const j = this.checked_mails.indexOf(uid)
      if(i>=0 && j!==-1 && this.mails[i].threads && this.mails[i].threads.length > 0){
        this.uid_for_removing = uid
        this.areYouSure2 = true
      } else
        this.delete_mail(uid)
    },
    removeMailsFromMailsList(uids){
      let mails_obj = {}
      this.mails.forEach(el => {
        if(!el.isChainLetter){
          mails_obj[el.uid] = el
          if(el.threads?.length > 0){
            el.threads.forEach(mail => {
              mails_obj[mail.uid] = mail
              mails_obj[mail.uid]['uid_parent'] = el.uid
            })
          }
        }
      })
      uids.forEach(elem => {
        if(mails_obj[elem]){
          if(!mails_obj[elem]['isChainLetter']){
            const i = this.mails.findIndex(mail => mail.uid === elem)
            if(i!==-1)
              this.mails.splice(i, 1)
          } else {
            const i = this.mails.findIndex(mail => mail.uid === mails_obj[elem]['uid_parent'])
            if(i!==-1){
              const j = this.mails[i].threads.findIndex(mail => mail.uid === elem)
              if(j!==-1)
                this.mails[i].threads.splice(j, 1)
            }
            const t = this.mails.findIndex(mail => mail.uid === elem)
            if(t!==-1)
              this.mails.splice(t, 1)
          }
        }
      })
      this.$store.dispatch('setUpdatedMailsList', this.mails.filter( mail => !mail['isChainLetter']))
    },
    async delete_mail(uid){
      this.processing = true
      const isCheckedMails = this.checked_mails.length > 0
      this.uid_for_removing = null
      let uids = []
      if(uid)
        uids.push(uid)
      else {
        if(this.checked_mails.length > 0)
          uids = this.checked_mails
      }
      if(uids.length > 0){
        this.isLoadingMails = true;
        this.isLoading = true;
        let actionPayload = {
          folder_messages: []
        };
      const isSearch = await this.$store.getters['getSearch']
      if(this.activeEmail > 0 && !isSearch){
          actionPayload['folder_messages'].push({
            folder_id: this.activeEmail,
            message_uids: uids
          })
      }else{
        let folder_messages = []
        let temp = {}
        uids.forEach(el => {  
          const k = this.mails.findIndex(mail => el === mail.uid)
          if(k!==-1){
            if(temp[this.mails[k].folder_id] && temp[this.mails[k].folder_id].length > 0)
              temp[this.mails[k].folder_id].push(el)
            else 
              temp[this.mails[k].folder_id] = [el]
          }
        })
        Object.keys(temp).forEach(key => {
          folder_messages.push({
            folder_id: +key,
            message_uids: temp[key]
          })
        })
        actionPayload['folder_messages'] = folder_messages
      }
        try {
          await this.$store.dispatch("deleteMessage", actionPayload);
          this.removeMailsFromMailsList(uids)
          if(uid) {
            if(this.opened_mail && this.opened_mail.message && this.opened_mail.message.uid && uid === this.opened_mail.message.uid){
              destroy_split()
              this.split_call = true
              this.mailIsOpen = false
              this.boxSplit = false
              this.opened_mail = {}
              this.subject = ''
              this.mailsItem = {}
              this.activeMail = -1
            }
            const i = this.checked_mails.indexOf(uid)
            if(i !== -1)
              this.checked_mails.splice(i, 1)
          } else if(this.checked_mails.length > 0) {
            if(this.mailIsOpen){
              const i = this.checked_mails.indexOf(this.opened_mail.message.uid)
              if(i !== -1){
                this.mailIsOpen = false
                this.boxSplit = false
                this.subject = ''
                this.mailsItem = {}
                this.opened_mail = {}
                destroy_split()
                this.split_call = true
              }
            }
            this.checked_mails = []
            this.firstCheckboxWithShift = -1
            this.firstUncheckedCheckboxWithShift = -1
          }
          if(this.hideMailsList){
            this.hideMailsList = false
          }
          if(this.activeEmail > 0){
            if(isSearch){
              const payload = {
                type: 'refresh',
                counter: Math.floor(Math.random() * 10000)
              }
              this.$store.dispatch('searchPagination', payload)
            }
            else
              this.loadMails(this.activeEmail, false, true)
          }
          const {msg_id, params} = this.$route.params
          if(msg_id && msg_id === uid){
            const route_name = this.$route.name
            let name = 'folder'
            if(route_name === 'search_message')
              name = 'search'
            else if (route_name === 'label_message')
              name = 'label'
            this.$router.push({name: name, params: {...params}})
          }
        } catch (err) {
          this.errorHandler(err)
        }
        this.isLoading = false;
        this.isLoadingMails = false;
        if(isCheckedMails && this.checked_mails.length === 0)
          this.animateNotification(!isCheckedMails)
        if(this.checked_mails.length === 0)
          this.isCheckedALL = false
      }
      this.processing = false
    },
    async mark(flag, uids, label_id){
      this.isLoading = true;
      let actionPayload = {
        flag: flag,
        folder_messages: []
      };
      if(label_id)
        actionPayload['label_id'] = label_id
      const isSearch = await this.$store.getters['getSearch']
      if(this.activeEmail > 0 && !isSearch){
        actionPayload['folder_messages'].push({
          folder_id: this.activeEmail,
          message_uids: uids
        })
      }else{
        let folder_messages = []
        let temp = {}
        uids.forEach(el => {  
          const k = this.mails.findIndex(mail => el === mail.uid)
          if(k!==-1){
            if(temp[this.mails[k].folder_id] && temp[this.mails[k].folder_id].length > 0)
              temp[this.mails[k].folder_id].push(el)
            else 
              temp[this.mails[k].folder_id] = [el]
          }
        })
        Object.keys(temp).forEach(key => {
          folder_messages.push({
            folder_id: +key,
            message_uids: temp[key]
          })
        })
        actionPayload['folder_messages'] = folder_messages
      }
      if(flag === 'flagget' || flag === 'unflagget'){
        uids.forEach(uid => {
          const i = this.mails.findIndex(el => el.uid === uid)
          if(flag === 'flagget'){
            const index = this.mails[i].flags.indexOf("flagged")
            if(index === -1)
              this.mails[i].flags.push("flagged")
          }else if(flag === 'unflagget'){
            const index = this.mails[i].flags.indexOf("flagged")
            if(index !== -1)
              this.mails[i].flags.splice(index, 1)
          }
        })
      }
      let label = null
      if(flag === 'labeled'){
        if(this.labels && this.labels.length > 0){
          const t = this.labels.findIndex(el => el.id === label_id)
          if(t!==-1)
            label = this.labels[t]
        }
      }
      uids.forEach(uid => {
        const i = this.mails.findIndex(el => el.uid === uid)
        if(i!==-1){
          if(flag === 'seen'){
            const index = this.mails[i].flags.indexOf("seen")
            if(index === -1)
              this.mails[i].flags.push("seen")
          } else if(flag === 'unseen'){
            const index = this.mails[i].flags.indexOf("seen")
            if(index !== -1)
              this.mails[i].flags.splice(index, 1)
          } else if(flag === 'unlabeled'){
            const t = this.mails[i].labels.findIndex(el => el.id === label_id)
            if(t!==-1)
              this.mails[i].labels.splice(t, 1)
          } else if(flag === 'labeled' && label){
            if(this.mails[i].labels && this.mails[i].labels.length > 0){
              const t = this.mails[i].labels.findIndex(el => el.id === label_id)
              if(t === -1)
                this.mails[i].labels.push(label)
            } else
              this.mails[i].labels = [label]
          }
          if(this.opened_mail?.message?.uid === uid){
            this.opened_mail.message.flags = this.mails[i].flags
            this.mailsItem.flags = this.mails[i].flags
            this.opened_mail.message.labels = this.mails[i].labels
            this.mailsItem.labels = this.mails[i].labels
          }
        }
      })
      try {
        await this.$store.dispatch("markMessage", actionPayload);
      } catch (err) {
        this.errorHandler(err)
        if(flag === 'flagget' || flag === 'unflagget'){
          uids.forEach(uid => {
            const i = this.mails.findIndex(el => el.uid === uid)
            if(i !== -1){
              if(flag === 'flagget'){
                const index = this.mails[i].flags.indexOf("flagged")
                if (index !== -1)
                  this.mails[i].flags.splice(index, 1)
              } else if(flag === 'unflagget'){
                const index = this.mails[i].flags.indexOf("flagged")
                if(index === -1)
                  this.mails[i].flags.push("flagged")
              }
              if(this.opened_mail && this.opened_mail.message && this.opened_mail.message.uid === uid){
                this.opened_mail.message.flags = this.mails[i].flags
                this.mailsItem.flags = this.mails[i].flags
              }
            }
          })
        }
      }
      this.isLoading = false;
    },
    async loadUserAccount(){
      this.isLoading = true;
      try {
        await this.$store.dispatch("loadUserAccount");
        const account = this.$store.getters["getAccount"];
        const a_id = this.$route.params.a_id
        if(a_id){
          let alias = this.$t('all_messages')
          if(+a_id === 0)
            alias = account.email
          else if(a_id !== 'all'){
            const i = account.aliases.findIndex(el => el.id+''===a_id)
            if(i!==-1)
              alias = account.aliases[i].email
          }
          await this.$store.dispatch("setLeftSidebarSelect", alias)
        }
        this.account = account
        if(this.account?.settings)
          this.slim = this.account.settings.slim_template
        if(this.$route.query.recipient){
          const newquery = Object.assign({}, this.$route.query);
          if(newquery['recipient'] === '1')
            this.create_mail(true,0)
          else
            this.create_new_mail_with_email(newquery['recipient'])
          delete newquery['recipient']
          this.$router.push({query: {...newquery}});
        }
        if(this.$route.query.sender){
          const newquery = Object.assign({}, this.$route.query);
          await this.$store.dispatch("setLeftSidebarSelect", newquery['sender'])
          this.create_mail(true,0)
          delete newquery['sender']
          this.$router.push({query: {...newquery}});
        }
        if(this.$route.query.group){
          const newquery = Object.assign({}, this.$route.query);
          this.loadGroup(newquery['group'])
          delete newquery['group']
          this.$router.push({query: {...newquery}});
        }
        if(this.account?.settings?.per_page && this.account?.settings?.image_display){
          this.amount = this.account.settings.per_page
          this.image_display = this.account.settings.image_display
        }
        const msg_id = this.$route.query.message_id
        if(msg_id)
          await this.loadMessageById(msg_id)
        else
          await this.loadFolders();
        this.$emit('onload_page')
      } catch (err) {
        this.$emit('onload_page')
        this.errorHandler(err)
      }
      this.$emit('change_status', 'failed')
      this.isLoading = false;
    },
    async loadMessageById(msg_id){
      try{
        const resp = await this.$store.dispatch("loadMessageById", msg_id);
        if(resp?.message?.folder_id && resp?.message?.uid){
          const newquery = Object.assign({}, this.$route.query);
          // delete newquery['message_id']
          this.isLoadingFolders = true
          this.isCreated = true
          this.activeEmail = +resp.message.folder_id
          this.activeMail = +resp.message.uid
          this.page = 1
          await this.$router.push({name: 'message', params: {a_id: 'all', f_id: resp.message.folder_id, page: 1, msg_id: resp.message.uid}, query: {...newquery}});
          this.loadFolders();
        }
      } catch (err) {
        this.loadFolders();
        if(err?.response?.status && err.response.status === 404){
          const newquery = Object.assign({}, this.$route.query);
          delete newquery['message_id']
          this.$router.push({query: {...newquery}});
          return
        } else
          this.errorHandler(err)
      }
    },
    async loadGroup(id){
      const payload = id
      try{
        const group = await this.$store.dispatch('loadGroup', payload);
        if(group){
          this.group_name = group.name
          this.group_id = id
          if(group.contacts && group.contacts.length > 0){
            let arr = []
            group.contacts.forEach(el => {
              arr.push(el.email)
            })
            const emails = arr.join(',')
            this.create_new_mail_with_email(emails)
          }
        }
      }catch(err){
        this.error = err.message || "smth went wrong";
        this.widthPB()
      }
    },
    startDrag(evt, uid) {
      evt.dataTransfer.dropEffect = 'move'
      evt.dataTransfer.effectAllowed = 'move'
      evt.dataTransfer.setData('itemID', uid)
    },
    updateFolders(folders, id, unseen){
      const i = folders.findIndex(el => el.id === id)
      if(i!==-1){
        folders[i].unread = unseen
        return 
      } else{
        folders.forEach(el => {
          if(el.subfolders && el.subfolders.length > 0)
            this.updateFolders(el.subfolders, id, unseen)
        })
      }
    },
    callOpenMail(mail){
      let unseen = false
      const i = mail.flags.findIndex(el => el === "seen")
      if(i === -1)
        unseen = true
      this.openMail(mail.uid, unseen)
    },
    arrowsHandler(){
      const th = this
      document.onkeydown = async function (event) {
        if(th.mailIsNotLoading)
          return
        if(event.keyCode == 38){
          if(th.opened_mail && th.opened_mail.message && th.opened_mail.message.uid){
            const i = th.mails.findIndex(el => el.uid === th.opened_mail.message.uid)
            if(i!==-1){
              if(i > 0)
                th.callOpenMail(th.mails[i-1])
              else if(th.page > 1){
                th.mailIsNotLoading = true
                await th.previous()
                if(th.mails && th.mails.length > 0)
                  th.callOpenMail(th.mails[th.mails.length-1])
                const mails_list_tag = document.getElementById("mails_id")
                if(mails_list_tag)
                  mails_list_tag.scrollTo(0,mails_list_tag.scrollHeight)
              }
            }
          }else if(th.mails) th.callOpenMail(th.mails[0])
  // up
        }
        if(event.keyCode == 40){
          if(th.opened_mail && th.opened_mail.message && th.opened_mail.message.uid){
            const i = th.mails.findIndex(el => el.uid === th.opened_mail.message.uid)
            if(i!==-1){
              if(i < th.mails.length-1)
                th.callOpenMail(th.mails[i+1])
              else if(th.page < th.pages_amount){
                th.mailIsNotLoading = true
                await th.next()
                if(th.mails && th.mails.length > 0)
                  th.callOpenMail(th.mails[0])
              }
            }
          }else if(th.mails) th.callOpenMail(th.mails[0])
  // down
        }
      }
    },
    myEventHandler(){
      const that = this
      if (window.matchMedia('(min-width: 768px)').matches) {
        that.isMobile = false
        setTimeout(()=> {
          if(this.split_call && this.mailIsOpen && this.hideMailsList)
            this.boxSplit = false
        },100)
      }
      if (window.matchMedia('(min-width: 1300px)').matches) {
        setTimeout(()=> {
          if(!this.split_call && this.mailIsOpen && this.hideMailsList) {
            destroy_split()
            this.split_call = true
          }
          if(this.split_call && this.mailIsOpen && !this.hideMailsList) {
            split_js()
            this.split_call = false
          }
          if(this.split_call && this.mailIsOpen && this.hideMailsList)
            this.boxSplit = false
        },100)
      }
      if (window.matchMedia('(max-width: 767px)').matches) {
        that.isMobile = true
        destroy_split()
        this.split_call = true
      }
      if (window.matchMedia('(max-width: 1299px)').matches) {
        setTimeout(()=> {
          if(!this.split_call && this.mailIsOpen && this.hideMailsList) {
            destroy_split()
            this.split_call = true
          }
          if(this.mailIsOpen && !this.hideMailsList) {
            this.hideMailsList = true
            this.boxSplit = false
            this.indexMail = -1
            if(this.split_call){
              split_js()
              this.split_call = false
            }
          }
          if(this.opened_mail && this.opened_mail.message && this.opened_mail.message.uid){
            const i = this.mailsFinal.findIndex(el => el.uid === this.opened_mail.message.uid)
            if(i!==-1)
              this.indexMail = (this.page-1)*this.amount+ i+1;
          }
        },100)
      }
    }
  },
  created() {
    const next = this.$store.getters["getConnection"]
    if(next)
      this.setConnectionOnMessage(next)
    resizer()
    const f_id = +this.$route.params.f_id
    if(f_id)
      this.activeEmail = f_id
    const page = +this.$route.params.page
    if(page)
      this.page = page
    if(this.$route.query.limit)
      this.amount = +this.$route.query.limit
    const lang = this.$store.getters['getLanguage']
    if(lang)
      this.lang = lang
    const msg_id = +this.$route.params.msg_id
    if(msg_id){
      this.activeMail = msg_id
      this.isCreated = true
    }
    if(this.$store.getters['user'].Email){
      this.isNotLoading = false
        this.loadUserAccount()
    }
    if (window.matchMedia('(max-width: 767px)').matches)
      this.isMobile = true
    this.arrowsHandler()
    window.addEventListener("resize", this.myEventHandler);
  },
  unmounted() {
    window.removeEventListener("resize", this.myEventHandler);
  }
}
</script>
<style scoped>
@media (min-width: 768px) {
  .popup__container {
    min-width: auto;
    width: 330px;
  }
}
.mails__item{
  -webkit-touch-callout: none; 
    -webkit-user-select: none; 
     -khtml-user-select: none; 
       -moz-user-select: none; 
        -ms-user-select: none; 
            user-select: none;
}
</style>
<style scoped>
.remove_all .button__text .spinner_class.lds-ellipsis div{
  background: #F54848;
}
.remove_all:hover .button__text .spinner_class.lds-ellipsis div{
  background: #cef;
}

.button--message .button__text .icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -6px;
}
</style>
