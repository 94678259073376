<template>
  <div class="popup">
    <div class="popup__container" :class="{'popup__container--label': addPopupLabelClass}">
      <form class="popup__form" @submit.prevent="saveLabel">
        <h2 class="popup__title">{{$t('label_creation')}}</h2>
        <button class="button button--action" @click="close" type="button">
          <SvgSprite class="button__icon icon" symbol="close" size="30"/>
        </button>
        <label class="popup__label label_popup" style="margin-bottom: 0px;">{{ $t('label_name') }}
          <input class="input" type="text" required v-model="name" :placeholder="$t('enter_display_name')"/>
        </label>
        <label class="popup__label" for="label">
          {{ $t('label_color') }}
        </label>
        <div>
          <input type="color" id="label" name="label" v-model="color">
        </div>
        <label class="popup__label">
          {{ $t('search_field')}}
        </label>
        <vue-select :select_values="search_fields"
          :_values="search_fields"
          :actual_value="actualFieldsValue"
          @change_value="setNewFieldsValue">
        </vue-select>
        <label class="popup__label">
          {{ $t('search_folder') }}
        </label>
        <vue-select :select_values="foldersNames"
          :_values="foldersIds"
          :actual_value="actualValue"
          @change_value="setNewValue">
        </vue-select>
        <label class="popup__label label_popup popup__label_margin" style="margin-top: 24px; margin-bottom: 0px;">
          {{ $t('search_query') }}
          <input class="input" type="text" required v-model="search_content" :placeholder="$t('enter_what_find')"/>
        </label>
        <button class="button button--primary" type="submit">
          <span class="button__text">
            <SvgSprite class="icon" symbol="folder" size="30"/>
            {{ $t('save') }}
          </span>
        </button>
      </form>
    </div>
  </div>
</template>
<script>
import VueSelect from '../helpers/VueSelect.vue';
export default {
  components: { VueSelect },
  name: "LabelsCreation",
  emits: ['save', 'close'],
  props: {
    subfolders: Array,
    activeEmail: Number
  },
  data(){
    return {
      name: '',
      foldersNames: [],
      foldersIds: [],
      actualValue: '',
      flags: ['trash', "sent", "junk", "drafts"],
      actualParentId: 1,
      search_fields: [],
      search_fields_for_request: ['subject', 'body', 'from', 'to'],
      actualFieldsValue: "",
      search_content: "",
      color: '#e66465',
      addPopupLabelClass: false
    }
  },
  methods: {
    close(){
      this.$emit('close')
    },
    saveLabel(){
      let search_field = ''
      const i = this.search_fields.indexOf(this.actualFieldsValue)
      if(i!==-1)
        search_field = this.search_fields_for_request[i]
      const actionPayload = {
        label: {
          name: this.name,
          color: this.color,
          search_field: search_field,
          folder_id: this.actualParentId,
          search_content: this.search_content
        }
      }
      this.$emit('save', actionPayload)
    },
    stringToColor(str){
      let hash = 0;
      let color = '#';
      let i;
      let value;
      let stringLength;
      if(!str) 
        return color + '333333';
      stringLength = str.length;

      for (i = 0; i < stringLength; i++) {
          hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }
      for (i = 0; i < 3; i++) {
          value = (hash >> (i * 8)) & 0xFF;
          color += ('00' + value.toString(16)).substr(-2);
      }
      return color;
    },
    setNewFieldsValue(val){
      this.actualFieldsValue = val
    },
    setNewValue(val){
      const i = this.foldersIds.indexOf(val)
      if(i!==-1 && this.foldersNames[i]){
        this.actualValue = this.foldersNames[i]
        this.actualParentId = val
      }
    },
    getFolderName(folder){
      if(folder.name === "INBOX")
        return this.$t(folder.name)
      if(folder.system){
        const i = this.flags.findIndex(el => folder.system === el)
        if(i !== -1)
          return this.$t(this.flags[i])
      }
      const arr = folder.name.split('.') 
      if(arr.length > 0)
        return arr[arr.length-1]
      return folder.name
    }
  },
  created(){
    if(this.subfolders && this.subfolders.length > 0){
      this.subfolders.forEach(el => {
        if(el.id && el.name){
          let name = this.getFolderName(el)
          this.foldersNames.push(name)
          this.foldersIds.push(el.id)
        }
      })
      if(this.activeEmail !== -1){
        const i = this.subfolders.findIndex(el => el.id === this.activeEmail)
        if(i !== -1){
          this.actualValue = this.getFolderName(this.subfolders[i])
          this.actualParentId = this.subfolders[i].id
        }
      }else {
        this.actualValue = this.getFolderName(this.subfolders[0])
        this.actualParentId = this.subfolders[0].id
      }
    }
    this.search_fields = [this.$t("subject1"), this.$t('letter_body'), this.$t("from1"), this.$t("to1")]
    this.actualFieldsValue = this.$t('letter_body')
    if (window.matchMedia('(max-height: 663px)').matches) 
      this.addPopupLabelClass = true
  }
}
</script>
<style scoped>
  .button--primary .icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -6px;
  }

  .button__icon {
    width: 30px;
    height: 30px;
  }
</style>