export default {
  setUserInfo(state, payload){
      state.user_info = payload
  },
  createContact(state, payload){
      state.contacts['contacts'].push({...payload})
      state.contacts['total']++
  },
  updateContact(state, payload){
    const i = state.contacts['contacts'].findIndex(el => el.id === payload.id)
    if(i!==-1){
      state.contacts['contacts'][i] = {...payload}
    }
  },
  removeContact(state,payload){
    const i = state.contacts['contacts'].findIndex(el => el.id === payload)
    if(i!==-1){
      state.contacts['contacts'].splice(i,1)
      state.contacts['total']--
    }
  },
  setContacts(state, payload){
      state.contacts = payload
  },
  setMails(state, payload){
      state.mails = payload
  },
  setFolders(state, payload){
      state.folders = [payload]
  },
  setFoldersCollectors(state, payload){
    state.foldersCollectors = payload
  },
  setLabels(state, payload){
      state.labels = payload
  },
  addNewLabel(state, payload){
      state.labels.push(payload)
  },
  setUser(state, payload){
      state.user = payload
  },
  setTotal(state, payload){
      state.total = payload
  },
  setAll(state, payload){
    state.all = payload
  },
  openedMail(state,payload){
      state.opened_mail = payload
  },
  removeMailsFromFolder(state, payload){
      payload.forEach(uid => {
          const i = state.mails.findIndex(el => el.uid === uid)
          if(i!==-1){
              state.mails.splice(i,1)
          }
      });
  },
  setUserAccount(state, payload){
    let aliases = payload['aliases']
    const wmid = payload['email'].substring(0,12)
    let a1 = []
    let a2 = []
    let a3 = []
    aliases.forEach(el => {
      let ar1 = el.email.split('@')
      if(ar1 && ar1.length > 0){
       if(ar1[0] === wmid){
        a1.push(el)
       } else if(ar1[0].length === 13 && ar1[0][0].match(/^[a-zA-Z]$/) && ar1[0].substring(1,).match(/^[0-9]{12}$/)){
        el.email = el.email.charAt(0).toUpperCase() + el.email.slice(1)
        a3.push(el)
       } else{
        a2.push(el)
       }
      }
    })
    a2.sort((a, b) => {
      const aliasA = a.email.split('@')[0]
      const aliasB = b.email.split('@')[0]
      return aliasA.localeCompare(aliasB)
    })
    a3.sort((a,b) => a.email.charAt(0).localeCompare(b.email.charAt(0)))
    a1 = a1.concat(a2)
    a1 = a1.concat(a3)
    payload['aliases'] = a1
    state.account = payload
  },
  setBa(state, payload){
    state.ba = payload
  },
  setLeftSidebarSelect(state, payload){
      state.left_sidebar_select = payload
  },
  setSettnigs(state,payload){
    state.settings = payload
    state.account.settings = payload
  },
  setLanguage(state,payload){
      state.language = payload
  },
  setFilters(state, payload){
      state.filters = payload
  },
  setStateConnection(state, payload){
      state.connection = payload
  }, 
  setGroups(state, payload){
    state.groups = payload
  }, 
  addGroup(state, payload){
    state.groups.push(payload)
  },
  addingContactsToGroup(state, payload){
    const i = state.groups.findIndex(el => el.id === payload.id)
    if(i!==-1){
      if(!state.groups[i].Сontacts){
        state.groups[i].Сontacts = []
      }
      state.groups[i].Сontacts = state.groups[i].Сontacts.concat(payload.contacts.contacts)
    }
  },
  deleteGroup(state, payload){
    const i = state.groups.findIndex(el => el.id === payload)
    if(i!==-1){
      state.groups.splice(i,1)
    }
  },
  updateGroup(state, payload){
    const i = state.groups.findIndex(el => el.id === payload.id)
    if(i!==-1){
      state.groups[i].name = payload.name
      if(payload.contacts) state.groups[i].contacts = payload.contacts.slice()
    }
  },
  setIsSearchMode(state, payload){
    state.isSearch = payload
  },
  setSearchPagination(state, payload){
    state.searchPagination.counter = payload.counter
    state.searchPagination.type = payload.type
  },
  updateMailsList(state, payload){
    state.mails = [...payload]
  },
  setAliasAccesses(state, payload){
    if(payload.delete_access){
      const i = state.account?.alias_accesses?.findIndex(el => el.alias === payload.alias)
      if(i!==-1)
        state.account.alias_accesses.splice(i, 1)
    }else{
      state.account?.alias_accesses?.push({alias: payload.alias})
    }
  },
  removeAliasAccessed(state, payload){
    if(!state.account?.alias_accesses || state.account?.alias_accesses.length === 0) return
    const i = state.account.alias_accesses.findIndex(el => el.alias === payload)
    if(i!==-1){
      state.account.alias_accesses.splice(i, 1)
    }
  },
  setCollectors(state, payload) {
    state.collectors = payload
  },
  setCollector(state, payload) {
    const i = state.collectors.findIndex(el => el.id === payload.id)
    if(i !== -1){
      state.collectors[i] = payload
    }
  },
  removeCollector(state, payload){
    const i = state.collectors.findIndex(el => el.id === payload)
    if(i !== -1){
      state.collectors.splice(i, 1)
    }
  }
}
