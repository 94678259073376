export const errors_en = {
    "alias_max_count_paying": "You have an unpaid alias!",
    "error_alias_status_paying": "You have an unpaid invoice!",
    "pay_invoice": "Pay the invoice in your WebMoney Keeper!",
    "confirmation_error": "confirmation error",
    "pay_invoice_cancel": "Invoice is rejected",
    "rejected": "invoice is rejected",
    "email_not_specified": "Recipient email not specified.",
    "message_not_found": "Message not found",
    "entity_not_found": "Entity not found",
    "internal_imap_error": "Internal imap error",
    "err_provider_already_exists": "provider already exists",
    "err_invalid_id": "Invalid id",
    "err_invalid_email": "Invalid email",
    "forbidden": "Forbidden",
    "invalid_auth_cookie": "Invalid auth cookie",
    "expected_token": "Expected token",
    "incorrect_login_or_password": "Incorrect login or password",
    "invalid_login": "Invalid login",
    "invalid_wmid": "Invalid wmid",
    "mailbox_not_exist": "Mailbox not exist",
    "duplicate_aliases": "Duplicate aliases",
    "max_count_aliases": "Max count aliases",
    "invalid_params": "Invalid params",
    "user_not_found": "User not found",
    "user_create_error": "User create error",
    "request_folder_id_error": "Request folder id error",
    "request_folder_not_found": "Request folder not found",
    "folder_sent_not_found": "Folder sent not found",
    "folder_draft_not_found": "Folder draft not found",
    "folder_trash_not_found": "Folder trash not found",
    "folder_already_exist": "Folder already exist",
    "folder_parent_wrong": "Folder parent wrong",
    "request_profile_id_error": "Request profile id error",
    "profile_not_found": "Profile not found",
    "request_label_id_error": "Request label id error",
    "specify_3_30": "Specify from 3 to 30 characters",
    "invalid_alias": "Invalid alias specified",
    "invalid_alias_wmid": "You can not create a 12-digit alias",
    "invalid_alias_purse": "You can not create an alias from a letter and 12 numbers",
    "invalid_email1": "Invalid email!",
    "invalid_wmid2": "Invalid WMID specified.",
    "internal_error": "Internal error",
    "folder_children_exist": "The folder contains subfolders!",
    "alias_has_no_at": "Email must contain '@' symbol and domain, for example: example@wmid.com",
    "invalid_request_params": "Invalid request parameters",
    "alias_stoplist": "Cannot be created with given name",
    "error_domain_status_paying": "You have an unpaid invoice!",
    "filter_name_is_empty": "Please enter a filter name!",
    "failed_validation_aliases_email": "Invalid alias specified!",
    "name_not_indicated": "You didn't specify a name!",
    failed_validation_email_address: 'Invalid email',
    "translate_fail": "Failed to translate message",
    invalid_port: "Incorrect port specified",
    request_code_not_found: "Invalid verification code entered.",
    cannot_connect_to_mail_server: "Cannot connect to mail server",
    collector_invalid_password: "Invalid password",
    cant_fetch_inbox: "Inbox not found",
    invalid_domain_for_mail_server: "Invalid domain",
    mail_server_not_found: "Mail server not found",
    collector_invalid_email: "Invalid email",
    collector_not_created: "Collector cannot be create",
    invalid_provider: "Invalid provider",
    invalid_token: "Invalid token",
    collector_not_found: "Collector not found",
    auth_error: "Authorization error",
    max_limit_reached: "Max limit reached: {limit}Mb"
}
