export const text_en = {
    "INBOX": "Inbox",
    "trash": "Trash",
    "sent": "Sent",
    "junk": "Junk",
    "draft": "Drafts",
    "labels": "Labels",
    "new_msg": "New message",
    "new_msg2": "Write message",
    "from": "From:",
    "from1": "From",
    "from2": "From:",
    "to": "To:",
    "to1": "To",
    "more": "More",
    "translate": "Translate",
    "original": "Original",
    "forward": "Forward the message",
    "reply": "Reply",
    "reply_to_all": "Reply to sender and all recipients",
    "previous_page": "Previous page",
    "next_page": "Next page",
    "contacts": "Contacts",
    "settings": "Settings",
    "mails_total": "Mails total",
    "refresh": "Refresh",
    "add_to_folder": "Move to folder",
    "add_to_spam": "Add to junk",
    "add_label": "Add label",
    "create_label": "Create label",
    "delete": "Delete",
    "remove_all": "Remove all",
    "mark_as_read": "Mark as read",
    "mark_as_unread": "Mark as unread",
    "mail_search": "Mail search",
    "mark_as_important": "Mark as important",
    "mark_as_unimportant": "Remove from favorites",
    "more_options": "More",
    "all": "All",
    "read": "Read",
    "unread": "Unread",
    "marked": "Marked",
    "unmarked": "Unmarked",
    "additional": "Add.:",
    "collapse": "Collapse",
    "in_full_screen": "In full screen",
    "close": "Close",
    "copy": "Cc",
    "hidden_copy": "Bcc",
    "whom_to_answer": "Reply to",
    "add_attachments": "Add attachments",
    "additional_options": "Additional options",
    "subject": "Subject:",
    "subject1": "Subject",
    "send": "Send",
    "date": "Date:",
    "original_message": "Original message",
    "login": "Login",
    "register": "Registration",
    "username": "Login",
    "password": "Password",
    "remember_me": "Remember me",
    "enter": "Enter",
    "as_wmt_user": "as WebMoney Transfer user",
    "attention": "Attention!",
    "enter_login": "Enter login",
    "enter_password": "Enter password",
    "enter_email": "Enter valid email!",
    "wrote": "wrote on",
    "all_messages": "All messages",
    "return_to_mailslist": "Back to inbox",
    "never": "Never",
    "always": "Always",
    "correspondent": "From correspondents",
    "new_message": "In new messages",
    "response": "In responses",
    "filters": "Filters",
    "folders": "Folders",
    "main": "Main",
    "profiles": "Profiles",
    "aliases": "Aliases",
    "add_contact": "Add contact",
    "total": "Total",
    "write": "Write",
    "add_folder": "Add folder",
    "select_mails": "Select all mails",
    "search_mails": "Search",
    "search_mails2": "Search",
    "new_folder": "New folder",
    "create": "Create",
    "folder_name": "Folder name",
    "folder_location": "Folder location",
    "enter_folder_name": "Enter folder name",
    "enter_request": "Enter search request",
    "additional_search": "Advanced search",
    "alias": "Alias",
    "clear": "Clear",
    "sender": "Sender",
    "recipient": "Recipient",
    "name_or_email": "Name or email",
    "date_from": "Date from",
    "date_to": "Date to",
    "alias_price": " Amount of payment: ",
    "list_aliases": "List of aliases",
    "list_aliases2": "Your aliases",
    "list_aliases3": "Service aliases",
    "unpaid_aliases": "Unpaid aliases",
    "get_passport1": "To create an alias, you need a passport at least formal.",
    "get_passport2": "To create a domain, you need a passport at least formal.",
    "available_aliases": "Aliases available to create",
    "alias_name": "Alias name",
    "create_alias": "Create an alias",
    "create_paid_alias": "Create paid alias",
    "add_new_alias": "Add new alias",
    "add_new_paid_alias": "Add paid alias",
    "aliases_available": "You have created the maximum amount of free aliases. You can create a new one for ",
    "new_aliases_available": "You have already created the maximum amount of free aliases! {br} Creating an additional alias is paid. {br_mobile} The price is {price}. {br} After specifying the name of the alias, you will be sent an invoice that must be paid.",
    "groups": "Groups",
    "group": "Group",
    "security": "Security",
    "of": "of",
    "used": "Used",
    "enter2": "Enter",
    "enter_word": "Enter the word ",
    "enter_wmid": "Enter wmid",
    "msg_sent": "Your message was sent successfully!",
    "letters_selected": "Messages selected: ",
    "select_all": " Select all messages",
    "in_the_folder": "in the folder ",
    "clear_selection": "Clear selection",
    "delegate": "Delegate",
    "delegation": "Delegation",
    "for_delegation": "for delegation",
    "delegated": "Delegated to",
    "delegating": "Delegated emails",
    indicate_delegate_wmid: "To delegate email {email}, specify the wmid of the new owner:",
    "confirm_d_email_success": "Email is successfully added!",
    "try_confirm_d_email": "Delegation request has been sent.",
    "my_ba": "My BA",
    "client_settings": "Settings for SMTP, IMAP, POP3 client",
    "smtp": "Use the following options to configure the SMTP client:",
    "or": "or",
    "imap": "Use the following options to configure the IMAP client:",
    "pop3": "Use the following options to configure the POP3 client:",
    "see_link": "See the link for more details",
    "wm_mail": "https://wiki.wmtransfer.com/projects/webmoney/wiki/WM_mail",
    "attempts": "Authorization attempts",
    "ip": "IP-address",
    "date2": "Date",
    "status": "Status",
    "comment": "Comment",
    "access_control": "Access control",
    "create_passw": "Create a password automatically",
    "set_psw": "Set a password manually",
    "code_sent_wm": "You have been sent a confirmation code to WM-keeper. Specify it in the field below.",
    "new_psw": "New Password",
    "again": "again",
    "enter_code": "Enter the code",
    "enter_new_psw": "Enter a new password",
    "reenter_new_psw": "Re-enter your new password",
    "code_sent_wm2": "Confirmation code sent to WM-keeper",
    "changes_saved": "Changes saved successfully!",
    "display_mails": "Displaying messages",
    "show_chain": "Show chain of messages",
    "letters_per_page": "Messages per page",
    "show_pictures": "Show pictures in message",
    "signature_setup": "Signature setup",
    "signature_response": "Signature in response",
    "add_signature": "Add signature",
    "notifications": "Notifications",
    "notify_in_wm": "Notify to WebMoney Keeper",
    "add_new_profile": "Add a new profile",
    "edit": "Edit",
    "editing_profile": "Profile editing",
    "creation_profile": "Profile creation",
    "display_name": "Display name",
    "enter_display_name": "Enter a display name",
    "organization": "Organization",
    "specify_organization": "Specify organization",
    "enter_value2": "Enter value",
    "use_default_profile": "Use default profile",
    "signature_options": "Signature options",
    "deleting_profile": "Deleting the profile",
    "are_you_sure1": "Are you sure you want to delete the profile for ",
    "yes": "Yes",
    "profile_saved": "Profile saved successfully!",
    "invalid_email": "Invalid email for ",
    "hidden_copy2": "hidden copy",
    "psw_match": "Passwords must match!",
    "psw_changed": "Password changed successfully.",
    "psw_sent": "The password has been sent to your WM-keeper.",
    "label_creation": "Label creation",
    "label_editing": "Label editing",
    "label_name": "Label name",
    "label_color": "Label color",
    "search_field": "Search field",
    "search_folder": "Search folder",
    "search_query": "Search query",
    "enter_what_find": "Enter what to find",
    "add_new_label": "Add new label",
    "label_removing": "Label removing",
    "are_you_sure2": "Are you sure you want to remove the label ",
    "folder_name2": "Folder name",
    "folder_creation": "Folder creation",
    "folder_editing": "Folder editing",
    "create_new_folder": "Create a new folder",
    "folder_removing": "Folder removing",
    "are_you_sure3": "Are you sure you want to delete the folder ",
    "folder_saved": "Folder saved successfully!",
    "letter_body": "Message body",
    "add_new_filter": "Add new filter",
    "filter_editing": "Filter editing",
    "filter_creating": "Filter creating",
    "filter_name": "Filter name",
    "for_incoming_mail": "For incoming mail",
    "complies_with_rules": "Complies with all specified rules",
    "matches_rules": "Matches any of the specified rules",
    "perform_actions": "Perform the following actions",
    "disable_filter": "Disable filter",
    "filter_removing": "Filter removing",
    "are_you_sure4": "Are you sure you want to remove the filter ",
    "filter_saved": "Filter saved successfully!",
    "add": "Add",
    "remove": "Remove",
    "was_read": "Was read",
    "tagged": "Tagged",
    "enter_value_field_above": "Enter the value of the field above",
    "has": "contains",
    "has_not": "does not contain",
    "is": "corresponds",
    "is_not": "does not correspond",
    "choose_action": "Choose an action",
    "fileinto": "Move message to",
    "fileinto_copy": "Copy message to",
    "redirect": "Redirect message to",
    "redirect_copy": "Send a copy of the message to",
    "setflag": "Set flags on a message",
    "discard": "Delete message",
    "stop": "Finish execution",
    "group_name": "Group name",
    "contacts_group": "Contacts in the group",
    "group_editing": "Group editing",
    "group_creation": "Group creation",
    "contact_address": "Enter contact address",
    "contact_groups": "Contact groups",
    "new_group": "Create a new group",
    "group_removing": "Group removing",
    "are_you_sure5": "Are you sure you want to delete the group ",
    "group_saved": "The group was saved successfully.",
    "name": "Name",
    "contact_name": "Enter contact name",
    "surname": "Surname",
    "enter_last_name": "Enter last name",
    "contact_editing": "Contact editing",
    "contact_adding": "Сontact adding",
    "enter_email1": "Enter email",
    "phone_number": "Phone number",
    "enter_phone_number": "Enter phone number",
    "date_of_birth": "Date of Birth",
    "enter_date_birth": "Enter date of birth",
    "add_to_group": "Add to group",
    "contact_information": "Contact Information",
    "are_you_sure6": "Are you sure you want to delete a contact ",
    "contact_removing": "Contact removing",
    "login_to_mailbox": "Login to mailbox",
    "message_decryption": "Message decryption",
    "paste_message": "Paste a message",
    "decrypt": "Decrypt",
    "message_decrypted": "Message decrypted",
    "logout": "Log out",
    "login_another_wmid": "Log in another WMID",
    "encrypt": "Encrypt",
    "text": "Text",
    "important": "Important",
    "contains": "Contains",
    "search_in_all_folders": "Search in the all folders",
    "search_important": "Search important in the current folder",
    "search_in_folder": "Search in the current folder",
    "mails_removing": "Mails removing",
    "are_you_sure7": "Are you sure you want to delete all mails in",
    "folder": "the folder",
    "label": "the label",
    "where_to_look": "Where to look",
    "contact_saved": "Contact successfully saved.",
    "current_msg": "Current message",
    "current_page": "Current page",
    "chain_messages": "Chain of messages",
    "encrypted_message": "Encrypted message",
    "there_are_attachments": "There are attachments",
    "not_indicated": "Not indicated",
    "download_original": "Download original",
    "show_images": "Show images",
    "msg_is_encrypted": "The message is encrypted.",
    "enter_msg_psw": "Enter the message password",
    "incorrect_code": "Incorrect code",
    "enter_psw_sender": "Enter the password given to you by the sender",
    "download": "Download",
    "invalid_email_for": "Invalid email for ",
    "recipient2": "recipient",
    "cc": "cc",
    "bcc": "bcc",
    "reply_to": "reply to",
    "no_messages_here": "There are no messages here yet",
    "are_you_sure8": "Are you sure you want to delete also messages chain?",
    "domain_text26": "Add a new TXT record with parameters",
    "data_processing": "Data processing",
    "removing_email_address": "Removing an email address",
    "are_you_sure9": "Are you sure you want to delete email ",
    "show_msg_preview": "Show message preview",
    "show_slim_template": "Show compact interface",
    "show_field_to": "Show recipient email",
    "set_password_access": "Password access",
    "password_access": "Access",
    "keeper_notification_duration": "Notification frequency to wm keeper (in minutes)",
    "save_changes": "Save changes",
    "minutes": "minutes",
    "copy_smth": "Copy",
    "email_address": "Email address",
    "are_you_sure_with_param": "Are you sure you want to delete {msg}? ",
    "you_wont_receive_msg": "You will not be able to receive messages to this email.",
    "psw_change": "Changing password",
    "ok": "OK",
    "are_your_sure_change_psw": "Are you sure you want to change your password? The new password will be sent to WM-keeper.",
    "in_search": " in the requested search",
    "show_slim_list_aliases": "Show compact list of aliases",
    "remove_from_spam": "Remove from spam",
    "confirm_d_emails": "Confirm",
    "copied": "Copied",
    "no_d_emails": "You do not have delegated emails.",
    set_alias_psw: "Set password",
    setAliasPswTitle: "Setting a password for email",
    areYouSureSetPsw: "Are you sure you want to set a password for {msg}?",
    resetPsw: "Change password",
    resetAliasPswTitle: "Changing password",
    oldPswWillnoValid: " The old password will no longer be valid.",
    deleteAlias: "Delete email",
    deleteAliasAccess: "Remove password",
    areYouSureRemovePsw: "Are you sure you want to remove your {msg} email password?",
    psw_deleted: "The password has been successfully removed.",
    psw_set: "Password set",
    description: "WebMoney Mail — an email service with a user-friendly interface for WebMoney system participants, ensuring effective communication.",
    collectors: "Mail importer",
    collectors_title: "Mail from other emails",
    collectots_subtitle: "Collect all your mails in one place",
    collectors_content: "The collector automatically transfers old and new mails from other emails, including those blocked in the Russian Federation, to Webmoney Mail. You can set up saving copies of mails in the original emails or disable this option.",
    collectors_settings: "Set up email collection from any server that uses the IMAP protocol.",
    another_email: "Another email",
    collector_indicate_email_data: "Enter information about the source mailbox",
    collector_indicate_email: "Indicate email",
    collector_indicate_psw: "Indicate password",
    collector_cancel: "Cancel",
    add_email_address: "Connect email address",
    host: "host",
    port: "port",
    next: "Next",
    collector_confirm_email: "Confirm email",
    collector_enter_data_by_hand: "Enter data manually",
    collectots_connected: "Connected email addresses",
    deleting_collector: "Disabling email collector",
    are_you_sure_collector:  "Are you sure you want to disable collector for email {email}?",
    collector_checkbox_remove: "Delete emails from source",
    auth_with_oauth: "Login to your {provider} account",
    back: "Back",
    disable: "Disable",
    enable: "Enable",
    collectots_disconnected: "Disabled email addresses",
    updated: "Updated: "
}
