<template>
<div class="footer">
        <!-- footer__button--spanish
              footer__button--portuguese
              footer__button--turkish-->
              <a class="footer__link" href="mailto:support@webmoney.com">support@webmoney.com</a>
        <button class="footer__button"
          :class="{'footer__button--active': isLangActive, 'footer__button--russian': lang === 'ru', 'footer__button--english': lang === 'en'}"
          @click="isLangActive = !isLangActive">
          <span class="footer__name">
            <SvgSprite class="icon" symbol="arrow-dropdown" size="24"/>
            {{language[lang]}}
          </span>
          </button>
        <ul class="footer__list">
          <li class="footer__item footer__item--english" @click="changeLang('en')"><span class="footer__name">{{language.en}}</span></li>
          <!-- <li class="footer__item footer__item--spanish"> <span class="footer__name">Español</span></li>
          <li class="footer__item footer__item--portuguese"> <span class="footer__name">Português</span></li>
          <li class="footer__item footer__item--turkish"> <span class="footer__name">Türk</span></li> -->
          <li class="footer__item footer__item--russian" @click="changeLang('ru')"> <span class="footer__name">{{language.ru}}</span></li>
        </ul>
        <span class="footer__copyright">WebMoney © {{current_year}}</span>
      </div>
</template>
<script>
import {URL_add_parameter} from '../helpers/Useful_methods'
import {setCookie, getCookie} from '../helpers/CookiesHelper'
export default {
  name: 'FooterLang',
  data(){
    return {
      lang: 'ru',
      language: {
        ru: 'Русский',
        en: 'English'
      },
      current_year: '',
      isLangActive: false,
    }
  },
  methods: {
    changeLang(lang){
      if(lang !== this.lang){
        this.isLangActive = !this.isLangActive
        this.lang = lang
        setCookie('lang', lang)
        window.location.href = URL_add_parameter(window.location.href, 'lang', lang);
      } else{
        this.isLangActive = !this.isLangActive
      }
    },
  },
  async created(){
    this.current_year = (new Date()).getFullYear()
    try {
      await this.$router.isReady()
      const lang = getCookie('lang')
      if(lang) this.lang = lang
    } catch (err) {
      console.log('err :>> ', err);
      this.error = err.message || "smth went wrong"
    }
    window.addEventListener('click', (e) => {
        if(!e.target.closest('.footer')) {
          this.isLangActive = false
        }
    });
  }
}
</script>
<style>
  .footer__button .icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -24px;
    transition: 0.2s ease-in-out;
  }

  .footer__button--active .icon {
    transform: translateY(-50%) rotate(-180deg);
  }
</style>